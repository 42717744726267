import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/Loxone_Miniserver/ForumBox';
import PushHostAlarm from 'components/Software/Home_Automation/CGI_Galleries/PushHostAlarm';
import GoToPreset from 'components/Software/Home_Automation/CGI_Galleries/GoToPreset';
import SnapshotPath from 'components/Software/Home_Automation/CGI_Galleries/SnapshotPath';
import VideoLoxone from 'components/Software/Other_Platforms/Loxone_Miniserver/VideoLoxone';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Loxone Miniserver",
  "path": "/Software/Other_Platforms/Loxone_Miniserver/",
  "dateChanged": "2020-04-21",
  "author": "Mike Polinowski",
  "excerpt": "The Homeautomation Miniserver from Loxone connects all smart components in your home or apartment. All strands run together to the Miniserver to enable communication between the individual components: light, shading, heating, buttons as well as your INSTAR IP Surveillance Camera.",
  "social": "/images/Search/AU_SearchThumb_Loxone.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Loxone_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Loxone"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Loxone Miniserver' dateChanged='2020-04-21' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Homeautomation Miniserver from Loxone connects all smart components in your home or apartment. All strands run together to the Miniserver to enable communication between the individual components: light, shading, heating, buttons as well as your INSTAR IP Surveillance Camera.' image='/images/Search/P_SearchThumb_Loxone.png' twitter='/images/Search/P_SearchThumb_Loxone.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Andere_Plattform/Loxone_Miniserver/' locationFR='/fr/Software/Other_Platforms/Loxone_Miniserver/' crumbLabel="Loxone" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "loxone",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#loxone",
        "aria-label": "loxone permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Loxone`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#software"
        }}>{`Software`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#other"
            }}>{`Other`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#loxone"
                }}>{`Loxone`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#homeautomation-miniserver"
            }}>{`Homeautomation Miniserver`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#installation-of-the-miniserver"
                }}>{`Installation of the Miniserver`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#smart-home-app-and-webui"
                }}>{`Smart Home App and WebUI`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#control-instar-ip-camera-via-the-loxone-miniserver"
                }}>{`Control INSTAR IP camera via the Loxone Miniserver`}</a>
                <ul parentName="li">
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#example-1-triggering-an-alarm"
                    }}>{`Example 1: Triggering an alarm`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#example-2-go-to-a-saved-position"
                    }}>{`Example 2: Go to a saved position`}</a></li>
                </ul>
              </li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#display-your-camera-video-on-alarm"
                }}>{`Display your camera video on alarm`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#integrating-the-alarm-output-relay-of-the-camera"
            }}>{`Integrating the alarm output relay of the camera`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#integrating-the-alarm-input-of-the-camera"
            }}>{`Integrating the alarm input of the camera`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-the-loxone-miniserver-as-alarm-server"
            }}>{`Use the Loxone Miniserver as alarm server`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "homeautomation-miniserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#homeautomation-miniserver",
        "aria-label": "homeautomation miniserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Homeautomation Miniserver`}</h2>
    <p>{`The home automation `}<a parentName="p" {...{
        "href": "https://www.loxone.com/dede/produkte/hausautomation-miniserver/"
      }}>{`miniserver from Loxone`}</a>{` connects all smart components in your apartment or house. All strings run together to the Miniserver to enable communication between the individual components: Light, shading, heating, buttons as well as your INSTAR IP camera.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <VideoLoxone mdxType="VideoLoxone" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "installation-of-the-miniserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installation-of-the-miniserver",
        "aria-label": "installation of the miniserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation of the Miniserver`}</h3>
    <ol>
      <li parentName="ol">{`First, the Miniserver must be installed and connected in your distributor. The LAN cable must be `}<a parentName="li" {...{
          "href": "https://www.loxone.com/dede/kb/inbetriebnahme-miniserver/"
        }}>{`connected`}</a>{` to the network router (e.g. Internet W-LAN router) and the miniserver must be connected to power. After approx. 1 minute the Miniserver should `}<a parentName="li" {...{
          "href": "https://www.loxone.com/dede/kb/checkliste-miniserver-startet-nicht-mehr/"
        }}>{`be operational`}</a>{`:`}</li>
    </ol>
    <p><strong parentName="p">{`Miniserver: The left LED flashes green regularly. The right LED is off.`}</strong></p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`To configure the Miniserver you need a PC on which the program `}<a parentName="p" {...{
            "href": "https://www.loxone.com/dede/produkte/loxone-config/"
          }}>{`Loxone Config`}</a>{` is installed. This PC must be connected to the same network router (W-LAN or LAN) to which the Miniserver was connected.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`To create a new project in the software by clicking on `}<strong parentName="p">{`New Project`}</strong>{`, select the miniserver and enter the appropriate project data in the following input masks.`}</p>
      </li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`By clicking on the arrow below the `}<em parentName="li">{`Connect`}</em>{` button in the software, the search dialog can be opened. After the `}<a parentName="li" {...{
          "href": "https://www.loxone.com/dede/kb/miniserver-erstkonfiguration/#diagnosenetzwerk"
        }}>{`Miniserver appears`}</a>{`, it must be selected and enter the user data - `}<strong parentName="li">{`Default: user name: `}<code parentName="strong" {...{
            "className": "language-text"
          }}>{`admin`}</code>{` and password: `}<code parentName="strong" {...{
            "className": "language-text"
          }}>{`admin`}</code></strong>{`. By clicking on `}<em parentName="li">{`Setup`}</em>{` you get an overview of the configuration. After confirming this, you will come to the `}<strong parentName="li">{`Network Settings`}</strong>{` - specify here a static IP address that is available on your local network.`}</li>
    </ol>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`As a last point of the initial configuration the just created project must be saved in the Miniserver:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dc18449006a79fc7fcdfe349f084df0e/5a190/Loxone_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACaklEQVQoz02Q3YoURxiG+wIlIHggEfbEkNWQgCx6YrK5hVxAznIrm2TEEdGNbNTMuDPdXdVV9dX/73RXdXeYaQVfHoqiqKfq5auaZocI7jYf6e9/bOo1U0hpJYQihFLKAAQAZww4gNKSiVZqAEEp64QUlVJYcMoAE8UY7JUm2oCUFABrzbSm1jF5fKpWmirTMWgQ3jKohSRVB++cE9Yib2spdkLsjG28R9Y2wG83tze4vXW68wo7hZ3urERWohz5EKHabj51HVFKO+cZozfv/9nt6hiTs25fI8bN6/bNW/Jq4U13XK/p62typMIYkY4Ef4zSvO7+5ZzPp3gf05B+fvXw0dU3F6sHT1b3L1bfPlnd//7qzkIFjDVNOwxDKUUIUbe3GHeHw2GeZ+dC7OPzlw9/uLr7dHV28feDZy/Onr04+/HPez/9de/x1d2KEFLva+dsSklrDQCc81LKIqch/XZ9+fzld5frR5fr88v1+S/r81/Xjxcq2hFG6ZCHnLOUYldvNv9trLXzPFvrcs6pxJh9KiGVEEuIOcTsFypKKGpbd7onlSTQAkDO+Yt8rDCNc98P5bT/OlWHcb2vU0o5F6k4hu1+VzPGUoxK6aV/7L2Lduj7cRqnaZrmz1QY4xCjNRaAG2NSCAihpkXjOBpjQwzzPG/VDXb7Mozee61NOo3z+DMwFkOUUmKMrXWck48f3td1M02Tc3449W/MJwhdGUqMse/7cZw+yxhjjHDf94fDwVmvA27RHiOcUrJfySx0h9hro1NKx+KLzBgMw2CtVUo564wDhFtjbCnFnKZ4krfMozGPIYblZJH/B4yn8YvwwIHSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc18449006a79fc7fcdfe349f084df0e/e4706/Loxone_01.avif 230w", "/en/static/dc18449006a79fc7fcdfe349f084df0e/d1af7/Loxone_01.avif 460w", "/en/static/dc18449006a79fc7fcdfe349f084df0e/c9875/Loxone_01.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dc18449006a79fc7fcdfe349f084df0e/a0b58/Loxone_01.webp 230w", "/en/static/dc18449006a79fc7fcdfe349f084df0e/bc10c/Loxone_01.webp 460w", "/en/static/dc18449006a79fc7fcdfe349f084df0e/d00b9/Loxone_01.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc18449006a79fc7fcdfe349f084df0e/81c8e/Loxone_01.png 230w", "/en/static/dc18449006a79fc7fcdfe349f084df0e/08a84/Loxone_01.png 460w", "/en/static/dc18449006a79fc7fcdfe349f084df0e/5a190/Loxone_01.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dc18449006a79fc7fcdfe349f084df0e/5a190/Loxone_01.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "smart-home-app-and-webui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#smart-home-app-and-webui",
        "aria-label": "smart home app and webui permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smart Home App and WebUI`}</h3>
    <p>{`From this point it is possible to use the `}<a parentName="p" {...{
        "href": "https://www.loxone.com/dede/produkte/apps/"
      }}>{`Smart Home App`}</a>{` to connect to the Miniserver with your mobile phone, tablet, PC as long as you are in your home network. Download the apps directly from the `}<a parentName="p" {...{
        "href": "https://www.loxone.com/dede/support/downloads/"
      }}>{`here`}</a>{`, or in the respective APP Store and log in to the Miniserver with the user data that you specified in the steps before during the initial configuration.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1b5d99835f77a1f410029106acc7da7b/5a190/Loxone_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.43478260869566%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC+0lEQVQoz03R3W7bNhyHYQ0Y1syyFNmxa8f1t2SJkiiKpCjqy7JEWYrt2EmatsOOVqAYsBY72W0Mw7Ad9IYHJ0PQC3jw/v6k9PW3sW90B4Ph9M21pspK60Jrtzpqq6vIXVm+ass9rd3rKN3Ltqa0Oqrc1ZTOlaZ2tfH4WkqiEIJVT23Phj1zcu0tJ4G1iB2j5M7tPT2+Y3WGC4ru6uLnx7uf7o7Hh9MXsf3j8X3z4UGyzCVYzfTpwNXH2JqFrp75ZkntmxTta7JrgjqnVUr3VXZ/rPc7sa7zQ57u6pIfKmnU1xajvj0fIWPK7EXqrUpi18ytmVcxvwpxFVORhmUaZgkL48CPqMnJmHqrhEn6mwGYjTx9yqxFAlcFBnXwJEMkOC4jUsaBSHme8igJcRzYITUYGRG0SJgEZiOkTxk4yxLbZxmeZZ2y33/9+PnTL9U6/l9Ggc2pzvAk8HsYTiIqPcnlt80txxUnu03y9d+///nrT1Fk/NxkZxmSceC/pkjz3XFEpcB6acLzWu5XEX1aSx9P+/vTnkUB4vS5OQ78PvWuMLz03FFIpAQa5cudZ0m2CbtryuNNtWtE1YhNI0KRG89N4nUx7PhQgfaQYelJvjSJiEkR06bIPry7Pz2cTu/fitN+FQUj4vUxPEsENc+VHfCa+tK3a0VEd2V2qIta5GJbpiLn1QZtslUWLbJ4yHAHuZrnqtBp2VafIOmlWcWBSNhtIx5Oh9vTobip8n0dNgJtS9gIsC0mCb+EjgqdtmNfWOaV70lViCpORExFwp7/c71J19UmKNd+sbbWyTJP5nkyYERDrgLttgNatvWDueogKG3PLxRUaSiyaJNFPA79KLBCPCPeiKIehiq0Lz1HhbbsANmxL4D1CljfLXXFtaUYOzGBMUWc+pQiP/YtH+rIBTEbQNCD9iLhKjAVa9VD8EdjebFcfj+fKQCMWSBhx8SOhaHtucDxgFfAOTQnwCRl3jX1jmU4m0xezDXTNJKotZi9mk6k4XDOo+iw+w964LUSE2+AkwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1b5d99835f77a1f410029106acc7da7b/e4706/Loxone_02.avif 230w", "/en/static/1b5d99835f77a1f410029106acc7da7b/d1af7/Loxone_02.avif 460w", "/en/static/1b5d99835f77a1f410029106acc7da7b/c9875/Loxone_02.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1b5d99835f77a1f410029106acc7da7b/a0b58/Loxone_02.webp 230w", "/en/static/1b5d99835f77a1f410029106acc7da7b/bc10c/Loxone_02.webp 460w", "/en/static/1b5d99835f77a1f410029106acc7da7b/d00b9/Loxone_02.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1b5d99835f77a1f410029106acc7da7b/81c8e/Loxone_02.png 230w", "/en/static/1b5d99835f77a1f410029106acc7da7b/08a84/Loxone_02.png 460w", "/en/static/1b5d99835f77a1f410029106acc7da7b/5a190/Loxone_02.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1b5d99835f77a1f410029106acc7da7b/5a190/Loxone_02.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The web interface of the miniserver is via the local IP of the server in your network and the login you entered in the setup for the item `}<strong parentName="p">{`Visualizations`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/83400ff1bb8f15546e24850a93712271/5a190/Loxone_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADjElEQVQ4y13S608cVRjH8YlaAux9Zi9nZs7O9czlnDlz2Z1lL+xuu8suLAtUpBKjaUshtUlFMDUhsSpQbeI/VApRSppQ275AQWOsMf0jtE1DfeELDTTGpMn37SfP78XDtN7ujvUm62c75dFmudZs1M91u712u1tvdU4693qNVrfZ7rbHJ8+OTTClWrVcr1cajbBaC0vl94Pi1ZHqrBuYhBLqYeph1yOuT1wfn+TRoOgGxWZrrFSpMWpVtDuyO6PTniZoaRTwhgOgwPJpFoKsLAJFElUFqmpeUaGkQCEvADGXAVk2k2biqUiCjaa4GJdNcGwsm06CHCsADoKMAoEuiUiVkC7rSNaQImuyKEOQF9ICYHMZJjo8FIsMJ6IRNh7JpOKASwoZTgIZLQ8MDVpItk3NtjXLRshCiqlBXQWKlJZEVuSZeCySTES5ZDSbioN0SsxxMp/V8jzKiwhAEyrYRMSxbMdGjqVgE1oIII1T5ZQEmWQ8mk7GXkl4IjN6njeg6JdQdzXsrpYKoxQTbHtEd4nsYIFYGQuldC2hygyXiGXZBH8i0wqfQZC3VWgAcXypfPvp8jdPP+leHbVMbBddLaB5lwCC09hKmiiGNOZ/KWQR5C0JEl3Goty5PPLZD4s3D5baizULUzP0lcATfZqlJEXsGDaHTZ15TTqq7Bmab6FSETfngsa7Rb8SkGLBCAtS0Qe+y3lOnOIItgdti4G5tCrkDMjbp9JRJSxJRFVatdGTbxrrVRpNhToidfnAzxW8lEdj1Bly8IBtMwqfNSCPZZFqEtXkVs2//N7ExfnxhYszV67MLixd+GBxbu7S+dmFWdqps56bDNyI6ww6+AzBDILAPpWhrRuC8NX64ou/dv54sfX85c7z4+1nL7efHW//ebz99z97K7c+HDAsNvSHPTpAnbccwliyQDUpMNQRrBeQOj/V/nr9o43Pr2+uL29sfPzl5srNW6trm6trt2/U3unFPZoo+kM+PeM6bxLCEC0fIKVka1XHqDlGpxJe6E+eH+/OTPRm+v3+9PTY1HSzP1XtT+mNGlvw4gVvyHdPMWZcTQotteKgpo9DJH3x6crvT349+vHg56PDx4++f/jo4eFPRwdHh7/89uTSjdVBbCTD4NXlNwhhAiSXsT5KzZZPypaytnzt8YP9+7vf3t/bvbt1Z+vune/27u3c293b35+/fm2YmImiP/jf7H8BTW/eQBFlaIsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/83400ff1bb8f15546e24850a93712271/e4706/Loxone_03.avif 230w", "/en/static/83400ff1bb8f15546e24850a93712271/d1af7/Loxone_03.avif 460w", "/en/static/83400ff1bb8f15546e24850a93712271/c9875/Loxone_03.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/83400ff1bb8f15546e24850a93712271/a0b58/Loxone_03.webp 230w", "/en/static/83400ff1bb8f15546e24850a93712271/bc10c/Loxone_03.webp 460w", "/en/static/83400ff1bb8f15546e24850a93712271/d00b9/Loxone_03.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/83400ff1bb8f15546e24850a93712271/81c8e/Loxone_03.png 230w", "/en/static/83400ff1bb8f15546e24850a93712271/08a84/Loxone_03.png 460w", "/en/static/83400ff1bb8f15546e24850a93712271/5a190/Loxone_03.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/83400ff1bb8f15546e24850a93712271/5a190/Loxone_03.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "control-instar-ip-camera-via-the-loxone-miniserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#control-instar-ip-camera-via-the-loxone-miniserver",
        "aria-label": "control instar ip camera via the loxone miniserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Control INSTAR IP camera via the Loxone Miniserver`}</h3>
    <p>{`In order to control our IP camera via the Loxone server, we need to create a virtual output and assign it a button in the UI and a CGI command for the camera:`}</p>
    <h4 {...{
      "id": "example-1-triggering-an-alarm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example-1-triggering-an-alarm",
        "aria-label": "example 1 triggering an alarm permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example 1: Triggering an alarm`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e1c7c77a4ec62dd2fb4d1ba876c4594f/5a190/Loxone_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "91.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC+ElEQVQ4y4WTW2+kRhCF+f8/IY9RnvKUe6REkSLH8SrxOl5PxpcZZoCB5jIDDA0DNDSXLwKvV7Z2vSnpqLparaNTdboMv/DI0ohDGlKoglZrOj3Q6xGlGlrVMtYdQ6VJ44RE+PiWTRZF9MNA13V0naYsS06nE4bjbfFchyDySJM9SbDHFJc4wTuiJMRd/oX525dY299J1u84LBdU93dUfkCpasrTaSZK45hkv8eI/IjIF5jmGuEJtqsttnuPCDYkWUb47w2rX77hYXPD7s0F5vk5+8srqrykqhuqSlGWNdUwUANGXD2gupK6zZEnn+zkIQsfmQuy0kfuTeT6iihYIXYLDrsFx2jF8WjNSFOLPN+Rn/1B+sVXGO7bX4lv35HcXRPfXiLX11T2LaW1pLaXxDcXpJmgqg6oJqHRGapJqev4A5omofznmujr7zAezi5YLe84JUeqo+TgBZSpRJ0q1CHluLzFFlsCP0Kplr6bjPgEADMMMVLbxo8iur5nirpRqKahYwTd0ocR4rjCcTa0reYxRl7GY31MjxipZVOUJcMw0Pc9RV6QZRmN1gx1TecHOMEGa7uhKivGcfwkpshkjuEtl7hCoOqatm1J4pgwCIiThEZK+iAkjCNsyyJJErTuHjW9J3lBmOUYkblhbTv4YUgqc/QwPAIYWj0rjI8Z7s5FZnLu5Dnh8/OscP/3JburK9y3VyR392jho4VA+wHN1pprJ9xgrh9wd968Gc+VfaQQrZkxPWxbRqUYVTNjUIqh7wn2Lltrw2F/QGvNayFlgTG+9+g5nns3DCPe/p6da5FlclY4mTflifwJ0yiSyeVXXZvzwDiMJJnAdW2srYXnefPuFkUxzzSXOVLK+S4IIozXHHush7lWVYXvuji2g23ZL968aDkvPk/45GhelZj2Fi+KOEj58bd+YcrnCKfzAHK9wDz7HvvsT7yLN7Su92GOT3nelEz+X8sj4wC1u8E8/5bFTz+z+OFHVtM3EwLHcWYIIealOMQJ/wGbtVvvWlv4GwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e1c7c77a4ec62dd2fb4d1ba876c4594f/e4706/Loxone_04.avif 230w", "/en/static/e1c7c77a4ec62dd2fb4d1ba876c4594f/d1af7/Loxone_04.avif 460w", "/en/static/e1c7c77a4ec62dd2fb4d1ba876c4594f/c9875/Loxone_04.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e1c7c77a4ec62dd2fb4d1ba876c4594f/a0b58/Loxone_04.webp 230w", "/en/static/e1c7c77a4ec62dd2fb4d1ba876c4594f/bc10c/Loxone_04.webp 460w", "/en/static/e1c7c77a4ec62dd2fb4d1ba876c4594f/d00b9/Loxone_04.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e1c7c77a4ec62dd2fb4d1ba876c4594f/81c8e/Loxone_04.png 230w", "/en/static/e1c7c77a4ec62dd2fb4d1ba876c4594f/08a84/Loxone_04.png 460w", "/en/static/e1c7c77a4ec62dd2fb4d1ba876c4594f/5a190/Loxone_04.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e1c7c77a4ec62dd2fb4d1ba876c4594f/5a190/Loxone_04.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Access your `}<strong parentName="li">{`Project`}</strong>{` on the Loxone Miniserver and click on `}<strong parentName="li">{`Virtual Outputs`}</strong>{`.`}</li>
      <li parentName="ol">{`Now a button will appear at the top of the menu bar where you can create a `}<strong parentName="li">{`Virtual Output`}</strong>{`.`}</li>
      <li parentName="ol">{`Clicking on this button opens the configuration page where you can assign a camera to the output via its IP address.`}</li>
      <li parentName="ol">{`Then click `}<strong parentName="li">{`Virtual Exit Command`}</strong>{` to assign the output a command that you want to send to the camera when you press the button.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/872fc46a579fa88a9a397595d7a27092/5a190/Loxone_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB6ElEQVQoz42SbW/TMBSF+/9/C5+ZYEgIIT4C01hJujVNGjdJ4zZt3uw4cdoHxYNBpTHtSpYV36sn59hnFtzeIrKMM1A1DbmUNFrTljW7xR2BWHAojsCZ0+nE+XwmTTPGceS5mu1XIdtcYoeBruuo65qqrjFVQytC/PCGY1G64Qk2lVKafhiYPqezf9csXwYkaUZVVlRVhVKK8XQCa1FCEKcCKXf0pn9S0bSKYbAXP3lSuPE80jx3drRSbLMtddtyMgYtNiTbjGK/d/1XAfMgYC02Tt1keRh6TG+wStGsI/zlnIV375S/ChjPf7KKIlTb0raNU7Mv9pRyh9rErNMQmcuLR5ju8L9AGawIozVpkqJ197fTW3SyRsglu3xP27YYYzBdx744vAScLAvyPEdr/fha06Dp6bOMbZGxWoUEYYjujIM6oL0E/tldbKI4ZiOEu8OnAWMY5Y4sFjz8uCPyF1ilXa+dLPc9/M7lRWwS38fzFwTL4DEy4+hiM+qOMUmJP18RX1+zu/4AUeiARiksz9es9n0O9w8c7x8wYsOQpAxpSh8LxkNJfYz5/vUbb97f8PZrxNWN4IuX0nsLhqt32FVI7yKnsdYy44UaT4/2Zd0wT0rCwri1zEq6uYf9+Amb55R1jZTSOfwF/duXirUG4IYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/872fc46a579fa88a9a397595d7a27092/e4706/Loxone_05.avif 230w", "/en/static/872fc46a579fa88a9a397595d7a27092/d1af7/Loxone_05.avif 460w", "/en/static/872fc46a579fa88a9a397595d7a27092/c9875/Loxone_05.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/872fc46a579fa88a9a397595d7a27092/a0b58/Loxone_05.webp 230w", "/en/static/872fc46a579fa88a9a397595d7a27092/bc10c/Loxone_05.webp 460w", "/en/static/872fc46a579fa88a9a397595d7a27092/d00b9/Loxone_05.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/872fc46a579fa88a9a397595d7a27092/81c8e/Loxone_05.png 230w", "/en/static/872fc46a579fa88a9a397595d7a27092/08a84/Loxone_05.png 460w", "/en/static/872fc46a579fa88a9a397595d7a27092/5a190/Loxone_05.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/872fc46a579fa88a9a397595d7a27092/5a190/Loxone_05.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Enter a name for the function you want to use with the CGI command. In the example we take the command that triggers an alarm on the camera. `}<strong parentName="li">{`Please note`}</strong>{` that this command only works with INSTAR Full HD cameras. However, if you have a camera from another series, you can easily exchange the command for `}<a parentName="li" {...{
          "href": "/en/Advanced_User/CGI_Commands/"
        }}>{`another CGI command`}</a>{`. The command requires the username and password of the administrator user to be appended to the back of the camera - in the example, this is `}<em parentName="li">{`username = admin`}</em>{` and `}<em parentName="li">{`password = instar`}</em>{` (if you select a CGI command here that can turn something on or off on the camera) these are to be entered accordingly with `}<strong parentName="li">{`command at ON`}</strong>{` and at `}<strong parentName="li">{`command at OFF`}</strong>{`, with the respective parameter.)`}</li>
    </ol>
    <EuiSpacer mdxType="EuiSpacer" />
    <PushHostAlarm mdxType="PushHostAlarm" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Then click on the exit, hold down the left mouse button and drag the object onto the work surface on the right.`}</li>
      <li parentName="ol">{`Afterwards we can create a `}<strong parentName="li">{`Virtual input`}</strong>{` with which we want to trigger the virtual output.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bb1a0923a90c9af8cff07790db3fbc71/5a190/Loxone_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACq0lEQVQ4y32SSW/cRhCF5/8fc8jJQIDAQeBDgCBwrkYsW3JsK8ZI0Qxn5TJsNpcZkkOyuS+fQcoSlMV+wEOhqrtfPXTVzDL3OJ5EfPqA9+Y9+pvfsPxbzqcT+h+/oF29wJAa8xsN4fp4/pFzqjD2O7T1isVGI4hD4iLH8ASz+CgJ0wh/u0TeXuP8eUFg74hiD//va8RfV0hHx3GM+yh0bGuLkDr+RuPw6gJ5+R7v3Ufk60tm3vUFiXfgZC4INx9Q9oLM0Tg7S0J9TnY+kClBlt0zTQ8EwZ6VpWG8fUf4/Y9EP/xM+OwnTt89Y7a9vEKsN+SeTywkieNSHkNyIZHzTwhPkiQZbdfTdT1FUWIdHMIoI0oL1DCQDz35GIGZu91xOp8ZUTcNURzT9D10A8lhy9qakyZqOh+GYYpJko4ZX4r/4OykGwTHE0WeT+z7/v5q3ZCaBtp2gStdmqbhAZnKadv2sclTzoS2wnYccqUoy5I0TWnalqGuKYWD6wdEUUTXdY+CaaYe8wfXD5hZd3fYjpwcjJeyLJsE+7Iis0ws5/Bfh98SDPZ7PN8njmOqqqQqC4qyoCsK1MFkpd9h6CZVXT0RzGm/Jmgvlziui1KKuq6J44gkTWnzAmWb7MUK3wuoqmpyNTJJs6873M3nrHY7HClRRTFNuu46KpVTSYEfOQjbQY7neT41DqOYbtyEfw1lEhQ3N1j7PcnxSJkklElKUxQ04yPX4xRGCMtCOuLRhcoUbfvF4bgVT6ec6wZnXSczLTo/oPV9Ws+nlS5dmmFYa26XGmtdEOcNaQthWU9C/4cZ30A/gHva8PtHjeevt7y8O/Ly7Z7F81/Jp7/OyE2DbLMhSZLpn2eT5Qc+XdKpNpCqENez8F0X3/cIbJtAW09i44qVpkkuJalSk+BnxodwKId0P1UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb1a0923a90c9af8cff07790db3fbc71/e4706/Loxone_06.avif 230w", "/en/static/bb1a0923a90c9af8cff07790db3fbc71/d1af7/Loxone_06.avif 460w", "/en/static/bb1a0923a90c9af8cff07790db3fbc71/c9875/Loxone_06.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bb1a0923a90c9af8cff07790db3fbc71/a0b58/Loxone_06.webp 230w", "/en/static/bb1a0923a90c9af8cff07790db3fbc71/bc10c/Loxone_06.webp 460w", "/en/static/bb1a0923a90c9af8cff07790db3fbc71/d00b9/Loxone_06.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb1a0923a90c9af8cff07790db3fbc71/81c8e/Loxone_06.png 230w", "/en/static/bb1a0923a90c9af8cff07790db3fbc71/08a84/Loxone_06.png 460w", "/en/static/bb1a0923a90c9af8cff07790db3fbc71/5a190/Loxone_06.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bb1a0923a90c9af8cff07790db3fbc71/5a190/Loxone_06.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Virtual input`}</strong>{` to create an input.`}</li>
      <li parentName="ol">{`In the opening configuration window you can give a `}<strong parentName="li">{`name`}</strong>{` to the input, select it in the `}<strong parentName="li">{`Visualization`}</strong>{` (WebUI) and as `}<strong parentName="li">{`Input Type`}</strong>{` choose `}<strong parentName="li">{`key`}</strong>{` (if you have chosen another CGI command next to the one on the camera) and turns off, you should instead choose a `}<strong parentName="li">{`switch`}</strong>{` here).`}</li>
      <li parentName="ol">{`Then pull the Input onto the work area.`}</li>
      <li parentName="ol">{`And connect the two elements.`}</li>
      <li parentName="ol">{`Save the change by clicking on the floppy disk icon in the top left corner and transfer the program with the `}<strong parentName="li">{`Save in Miniserver`}</strong>{` button.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9234b92c39da9c996c9bcb68838b0896/5a190/Loxone_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB+0lEQVQoz2PQ0TfQ0TPQ1NbRMzTS0dPX1tXV1TdUVlXPTE+9euXClcvnr16+cOP6ZSi6ceXalQs3Lp+7cencscMHGLi5OHi5Ofn5uAT5uUUF+SREBGQkxcQEuKMSM3dd+LL91JsdZ97tufBp9/lPey582n7qzeojL5ae/LDizOclB+4x8PPxCAvwignxS4oKSYsJqysruLu6GGkp2/inZLSezq3fm1O/P6NmX3rNvuz6A5nl2/xK9+jXXzSqv1I05xQDXKeshKiclJiBjkZZcaGzjYlDcJr/nLde0556T3/mNe2Z97RnvjNeOPXdtWm/Ztd2xanrZsPS8wxiQvxSIJ0iitLianLSWqqKJoZ6ehoKzuGZvjNeeUx66Dn5sfeUx16TH/tMfeLQedOs4YJl0yWHtmu1C08zQHQqSYmrykqpKMk72dt4ubu6O1t7xRX6zHjlMfmx15QnEOQ95Yn7xIcuvXede++6TXzYsuY6gxxYp5qslKqinIOKoom6ioqqsoaqrEtkDsjmyY+8piD0+0x75jv9uc+0Z/4zX/Zuvc+gLCWuISutpCC3UF75q7TyEhWNCdOnebra24Vm+M187THpkdfkJ8iafaY985761G/Gy54t9xjU5aR1FOU0VRRylFU2KKhWamhkFuTb2pg5hmf7gzQ/9pyCS/N9AIO16LO2YZHnAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9234b92c39da9c996c9bcb68838b0896/e4706/Loxone_07.avif 230w", "/en/static/9234b92c39da9c996c9bcb68838b0896/d1af7/Loxone_07.avif 460w", "/en/static/9234b92c39da9c996c9bcb68838b0896/c9875/Loxone_07.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9234b92c39da9c996c9bcb68838b0896/a0b58/Loxone_07.webp 230w", "/en/static/9234b92c39da9c996c9bcb68838b0896/bc10c/Loxone_07.webp 460w", "/en/static/9234b92c39da9c996c9bcb68838b0896/d00b9/Loxone_07.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9234b92c39da9c996c9bcb68838b0896/81c8e/Loxone_07.png 230w", "/en/static/9234b92c39da9c996c9bcb68838b0896/08a84/Loxone_07.png 460w", "/en/static/9234b92c39da9c996c9bcb68838b0896/5a190/Loxone_07.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9234b92c39da9c996c9bcb68838b0896/5a190/Loxone_07.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now when we access the web interface of the miniserver again, we can see that a button has appeared there. If we select this and press it, an `}<strong parentName="p">{`Audio Alarm`}</strong>{` is triggered on our camera and thus all programmed alarm actions are started - in the example the `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/Homematic_IP/#instar-ip-camera---homematic"
      }}>{`Alarm server was contacted`}</a>{`.`}</p>
    <h4 {...{
      "id": "example-2-go-to-a-saved-position",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example-2-go-to-a-saved-position",
        "aria-label": "example 2 go to a saved position permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example 2: Go to a saved position`}</h4>
    <p>{`For the second example, we can now recycle a lot of the previous one.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fe08b7d7b126604fcf55095635f4864e/5a190/Loxone_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB70lEQVQoz42QS4/TMBRG8+dZMho2I9EVSzbDgj8wCAmkjkQRmqFMoWma2HESO27i583LNkorJB5lNMdXV5Z1j671RShNiyIrKeakTov7fbVSSmXl3UPxEa/fF59WfLm0lBnotDbaWtN3puvm3vcRYzljlBCMUIpxUpQZY2VZZnG62a5uk9Wy+vpFtVzKxlhhRaMwOZVMUZTTlZS0bVHTxMZgrZFS6YHHSiEhUlVvjUyVSnkd7/A6vXlXPX/JXizo5aJ6dhnt4oQUudG6bVutNXQAFooSIZQIIUY3Tc5prTJEKFcVE8pYbecyXRfRqqoqGkJQStV17ZwLIWhod+heChWOeO/bVnjvw59ElFIhRAjBGMMoM8bMd6viZHPgzTRNpzkhlXN/61F+BACssVJKY4xzvoMuzzFjbBiG05yU6sxmQki8jRHGFsB7P02T914bHe9+5Jj0/aMyAGitrbXTHM0Jb0F/397v4gQAHpPDOWyn9/mG14dhGPwRIc7J/he/v/YDlDRDGSaEGGOUVHXN/91xZrP33nnfcI6y+YzHwMUTvz3LziG0z7I9r5n37ijrp8reB5zfrR/W628JKXlJOMVl1/cAMIx933cAMI7jf+QQmtWHN4vXVxfXr67eLi6uP9/cai0553MCQnDOrbU/AbAJUttBLxZiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fe08b7d7b126604fcf55095635f4864e/e4706/Loxone_08.avif 230w", "/en/static/fe08b7d7b126604fcf55095635f4864e/d1af7/Loxone_08.avif 460w", "/en/static/fe08b7d7b126604fcf55095635f4864e/c9875/Loxone_08.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fe08b7d7b126604fcf55095635f4864e/a0b58/Loxone_08.webp 230w", "/en/static/fe08b7d7b126604fcf55095635f4864e/bc10c/Loxone_08.webp 460w", "/en/static/fe08b7d7b126604fcf55095635f4864e/d00b9/Loxone_08.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fe08b7d7b126604fcf55095635f4864e/81c8e/Loxone_08.png 230w", "/en/static/fe08b7d7b126604fcf55095635f4864e/08a84/Loxone_08.png 460w", "/en/static/fe08b7d7b126604fcf55095635f4864e/5a190/Loxone_08.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fe08b7d7b126604fcf55095635f4864e/5a190/Loxone_08.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`First click on the `}<strong parentName="li">{`Virtual output`}</strong>{` that we created for our camera.`}</li>
      <li parentName="ol">{`And then on the `}<strong parentName="li">{`Virtual Output command`}</strong>{` to add another command.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8b20c92b3025dc2f3255c2cd14f806dc/5a190/Loxone_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.52173913043477%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACNUlEQVQoz4WSXW/TMBSG++O54YYPCSGB4F9wBXfdxcQGDHXtuqZtmjiJv2LHTmI7Thw7aK1gYpvg1Xtln8dHfs+ZcU5pSSCGEMEcp4TlleSIgkOyTsE2y2JeUWNarRtrdVeLFpOW0JNnKDvXtBDFhoGfNbxp8aaGK0mjWias3AoRN01S14eKx4BExXzOnr/lbz7wV+/LZy9n+/kZjKImy+huJ5JUIaTSFC6uKGdKNcPQD0OvVQvyAhS0QLypmz+e5csV5nyaJq11gVCBsZZtnUYRuG4bPR0VQqgq4X2Y/taMRVuEiWpao40bhtH7qR8USNb7a4Kp9+OpTmvj3PgQhssVZcx2VgohpfQhBGttASHBnHPn3KmubdU4+ocwWq9zhIzWfW/HcTzCvQIgRwUr2T2s9BNwcb0ERdGZzhgtRSVkpUTVgmS9W+63e2vtv2C4XB0A4IwZY6zpjLV9q00ODjD6f2e6iRIAWFkqpYw2puts01qUIw5ggcqy1Fq3raqEfCJtut6kWVZkudYmHO+DtSOhjLP0kAIAvPe/O9+nHUI4wtvdIc8hxta509HU9yMtkyzebG5Blrvx7sW7tB+PCl1cbheLw2KhCXWiGoQYCPGEYnmAMOEEW92M9m65lffdNPWDtV2ntXbOzRzjTkgnpK/rk0cpfT/UCi+2+0+X8eer9MsN3nxbli9e03cfVUkghHEcV1U1mx4pHO2GPkXox4583+GLHU5uYz8/G8+/Tt6ffhdC+AUJ10o/r5WlggAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b20c92b3025dc2f3255c2cd14f806dc/e4706/Loxone_09.avif 230w", "/en/static/8b20c92b3025dc2f3255c2cd14f806dc/d1af7/Loxone_09.avif 460w", "/en/static/8b20c92b3025dc2f3255c2cd14f806dc/c9875/Loxone_09.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8b20c92b3025dc2f3255c2cd14f806dc/a0b58/Loxone_09.webp 230w", "/en/static/8b20c92b3025dc2f3255c2cd14f806dc/bc10c/Loxone_09.webp 460w", "/en/static/8b20c92b3025dc2f3255c2cd14f806dc/d00b9/Loxone_09.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b20c92b3025dc2f3255c2cd14f806dc/81c8e/Loxone_09.png 230w", "/en/static/8b20c92b3025dc2f3255c2cd14f806dc/08a84/Loxone_09.png 460w", "/en/static/8b20c92b3025dc2f3255c2cd14f806dc/5a190/Loxone_09.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8b20c92b3025dc2f3255c2cd14f806dc/5a190/Loxone_09.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Here you can store the stored position 1 for the `}<strong parentName="li">{`command for ON`}</strong>{` and the stored position 2 for `}<strong parentName="li">{`command for OFF`}</strong>{`:`}</li>
    </ol>
    <EuiSpacer mdxType="EuiSpacer" />
    <GoToPreset mdxType="GoToPreset" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`And drag the configured output to the work area.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2669390c7bf966d48e95db2c8dd8b1f3/5a190/Loxone_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACn0lEQVQ4y31S247URhD19/MYiPIUeEUoSEQBiSDY3YSFSLMXtJOdmZ0Ze8bXvrjb7b760m5PI+8KhFjEUT2UWn2qTlWdAGqMZh/x2YdOq0aptmuVkq2Uru+5ECXGECGttRvHYXDGGCGkVlpwLoQIkngHSgBuluj0PJy9TvD/rKp2p3+tZn+kJFwsNphWmFAudZYki8X1/HoOMKSc7WEWkAqSmoF9lF2c5Z9mCKeUlXD2YX/yJkujfbxLk12a7uM4TOIt2Efp1RyubspNCK8XAS4uNMkF2op8buhW01CRdV2ujYiNzpomb5qiafKqihK4Lv77SH95TB89Jg9/Jw9+DdZvj/LlUsRxFe14HMssM2GUbxZJkXAuhltopdIMICIAYlJpbcwUTROgbUil9N5rYwBCmLG25holu2IpufLeHw7ee19zcbjLvkFAtiGruRuGoe9t34/+4LXRII+SLatq59zdPym1c+N3/AAsVxCXvK55zbU205sxHS4LBCmh1tqv5HG81zm+ukKEjG66oVLKOueE1ADEWYwgsnb4GZmEYQERq5gd7CR96EcpDQLraBlFu7btfkbOrxeYVl3bWmtrxoSUTkiDiyhfk5JYa+/mFFKN9xe2OTtfbTYAAG2aru97N3SMtwRCkmRpBgEwWispCa38PQTpxWUSJ5Ixw4XhfOj7viQjrbhSGYApgHYcJ9lCjePXbR/ukoAvV+XNmoeRw+UAkUXYJqlDOJl/yi8v+Hrlu3YiN90PZN8Xc7g1Rvr66Zvfnrx4+Ozk+dHJ8Tw+PZe8RhhLUYualoQKzoPDF/gvcRgnp8Dw7N+j9y9fzY7fXR4fXe3//JtGYYYxjbb4/T95mmGMf9R5KuEb22JaEILKEhKKMWP+dvgJw+BvnfcZ7QuzYXrjGLMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2669390c7bf966d48e95db2c8dd8b1f3/e4706/Loxone_10.avif 230w", "/en/static/2669390c7bf966d48e95db2c8dd8b1f3/d1af7/Loxone_10.avif 460w", "/en/static/2669390c7bf966d48e95db2c8dd8b1f3/c9875/Loxone_10.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2669390c7bf966d48e95db2c8dd8b1f3/a0b58/Loxone_10.webp 230w", "/en/static/2669390c7bf966d48e95db2c8dd8b1f3/bc10c/Loxone_10.webp 460w", "/en/static/2669390c7bf966d48e95db2c8dd8b1f3/d00b9/Loxone_10.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2669390c7bf966d48e95db2c8dd8b1f3/81c8e/Loxone_10.png 230w", "/en/static/2669390c7bf966d48e95db2c8dd8b1f3/08a84/Loxone_10.png 460w", "/en/static/2669390c7bf966d48e95db2c8dd8b1f3/5a190/Loxone_10.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2669390c7bf966d48e95db2c8dd8b1f3/5a190/Loxone_10.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Now click on `}<strong parentName="li">{`Virtual Inputs`}</strong>{`.`}</li>
      <li parentName="ol">{`And on `}<strong parentName="li">{`Virtual Inputs`}</strong>{`.`}</li>
      <li parentName="ol">{`As display in the visualization (WebUI) we select a `}<strong parentName="li">{`Switch`}</strong>{` here.`}</li>
      <li parentName="ol">{`Then pull the entrance onto the work surface.`}</li>
      <li parentName="ol">{`And connect both outputs (from the virtual input) to the previously created virtual output.`}</li>
      <li parentName="ol">{`Save the settings.`}</li>
      <li parentName="ol">{`And transfer the program to the miniserver.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c699f7b98c62132b3d0ca622ac08104f/5a190/Loxone_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADbElEQVQ4y03N209TBwCA8ZM4EyYtWEq5SS+n535Oz/3S0xtUbCk4RVBaLpZJMgFhGobJyB6W7U0zN7PMxc3/9ltcsmQPv+R7+4R7Dwd01zaI0iaOH+GGCV5UR9UtREmlKmv/+tz/qUra/6ioqoFpOmi6heBHCa1Ol6TRot5sEyUpUb1Bo9UhTFJcL8a2QxwnxPs8C2Msx8MNYizXp+Z4aF5AutLF8QIESVe5MXGTW1MZJqezZG5nyeay5PLT5As5SuYSclxGiUXulJcoikW8JKAW2tihh+zWeKSb9Ot1yjUDYdMPUDJZrJk8Vi6HOzuLPzdHMD9PtLjIqlOl39DoJyptUaQjyzxpNdlupDyIEnpByHduxGnnLl3PR3hb1HkvObyXbP5QPd5VTH4TLf6sxXxs9fjY6PEx7fFXa8Cn1QF/xx0+6AEfrJjf9YB3ks1bzeeN7PCr4iFMTk0ymZkgTRMODvbp99fQNZmF+Tyl0iKiuIwsl1H0KoohI5sKsqlR1mWqtknaX6MgVZiplJgqLSPkpzPkpybZur/JyfExR/sjmpHHciGHUlpCrSyjS2UMtYquy+iGimKqVDQFyTZpr/dYUCVmJZFcpYxQyE0xNzONLlVwTQ2tUsTRZOqeQ+o7pJFHMwlppjGNZp20nRK3U/xWipXGVFybgq6SUySmJRFhIZ9jLpdluL3F9dUlFyfP+eH1Fa8vX/H8+Iine0OOnu4zHh8w/voph8/G7B8f8fBgxPXPP3Fx/T1ZucptXSWrSAhLhRmWCzO044AH6/fod9psrnXpdVqsdzsM7nUZ9NdYH/Toba6ztjlgZXNAOuizur1F/f6AnKGRNTRuaQpCaWEWcXGOwNRpeA4rcUTTd/F0mbBmEDoWgWfjBx5OFGDGAUoUIEYB845Fxja5aZtMGjpf6hpCdWme6uIcuw/vc3l+xjfjQy7PTvn25Dkvz894eXHGq1cXvDh/wenLcx6PD9kaHzIYDdm7uuT9+IhfiiI3aiYTho6gFpdQiwtsb/Q5fTZmPNzh4PEj9re32H+yw97uDqPRLrt7I3YORjzYG7KxN+Tuk22+Ojvhx90hb8oSmZrJTdNAMMp3qFVLuHIFTxGJDIXY0klsk8StEfsOYejhxQG1JESvR1STiOU4YNZ3mPBsBNfmVs3kC8vkH9SbyAsYwEAdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c699f7b98c62132b3d0ca622ac08104f/e4706/Loxone_11.avif 230w", "/en/static/c699f7b98c62132b3d0ca622ac08104f/d1af7/Loxone_11.avif 460w", "/en/static/c699f7b98c62132b3d0ca622ac08104f/c9875/Loxone_11.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c699f7b98c62132b3d0ca622ac08104f/a0b58/Loxone_11.webp 230w", "/en/static/c699f7b98c62132b3d0ca622ac08104f/bc10c/Loxone_11.webp 460w", "/en/static/c699f7b98c62132b3d0ca622ac08104f/d00b9/Loxone_11.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c699f7b98c62132b3d0ca622ac08104f/81c8e/Loxone_11.png 230w", "/en/static/c699f7b98c62132b3d0ca622ac08104f/08a84/Loxone_11.png 460w", "/en/static/c699f7b98c62132b3d0ca622ac08104f/5a190/Loxone_11.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c699f7b98c62132b3d0ca622ac08104f/5a190/Loxone_11.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Click the button in the WebUI to pan the camera between the two positions.`}</li>
    </ol>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "display-your-camera-video-on-alarm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#display-your-camera-video-on-alarm",
        "aria-label": "display your camera video on alarm permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Display your camera video on alarm`}</h3>
    <p>{`To display the camera image in the Loxone Miniservers WebUI, we can add a `}<strong parentName="p">{`Custom Intercom`}</strong>{` from the list of `}<strong parentName="p">{`Networks`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a578a4cddfe573779a47ded0b16e9304/5a190/Loxone_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABYklEQVQoz42PzUvDQBDF+8d78SYieFIv4sGLUrCoiIpQPdS0itWYNppkP5PdTTbZTbaNTVrRigetxccchjfzY940ZMIEDwmBBENMfSaQlFxKHjEMIQAggNDXWmuVjst8lCYaYY3JvBqEd7USWhOtfaUClYEs81LpZcoD5MV2BgFEqVYQ2Ui84PY1X92MN7bj9S2+stZgUSSlNMYURWFMUZZjY0Y0oowEVuv05NA6P7q7bN0/394PX+HABSKVIokpY3GaNmaLlOXxMOgh1/X7j8hxMoyBMyAEIwi4CEOKPM/jnC+G81w57oM9dIeuCzEWScJEUtfTH2uL4bIsKaVJHFdVNXe0LiaTr342/dCfsDEFAEFI2WhcVnVd11Ol8qqq/3U5HxWu94QCbzIpv5zCfKdYAn/kectiy+rc9KBls3aftR8i4NP5aCn8+cw4RN3mwcX+5fHeSXP37GCnZV91fgd8B405MFq+1XVWAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a578a4cddfe573779a47ded0b16e9304/e4706/Loxone_12.avif 230w", "/en/static/a578a4cddfe573779a47ded0b16e9304/d1af7/Loxone_12.avif 460w", "/en/static/a578a4cddfe573779a47ded0b16e9304/c9875/Loxone_12.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a578a4cddfe573779a47ded0b16e9304/a0b58/Loxone_12.webp 230w", "/en/static/a578a4cddfe573779a47ded0b16e9304/bc10c/Loxone_12.webp 460w", "/en/static/a578a4cddfe573779a47ded0b16e9304/d00b9/Loxone_12.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a578a4cddfe573779a47ded0b16e9304/81c8e/Loxone_12.png 230w", "/en/static/a578a4cddfe573779a47ded0b16e9304/08a84/Loxone_12.png 460w", "/en/static/a578a4cddfe573779a47ded0b16e9304/5a190/Loxone_12.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a578a4cddfe573779a47ded0b16e9304/5a190/Loxone_12.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the configuration of the `}<em parentName="p">{`Intercoms`}</em>{` enter the JPG path of your camera - this is the IP address of the camera followed by `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/tmpfs/snap.jpg`}</code>{` and the administrator login to the camera (example s. below) and then drag it to the desktop:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SnapshotPath mdxType="SnapshotPath" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b9f6e726e10f3ba3d58c47a8c8c8307b/5a190/Loxone_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABj0lEQVQoz32RXY+TQBSG+f8/wPgrvNWod66mZl1b3e03pYWhAy1lWwrDwDDwbCBuoze+yZvJOSfz5jk5znY8QcQxbdeRKzW4AYwqSdYz3GDOc3oGOrquwxiDlBGhCAgCn2MSIaVg43mkaYqTLFdkeU6vUinOz2ds20JtyMWW5e4Xl3M2zPvAXte84HLJ0KrC1g22spSFptQljj+ZIKSkMYaiKMiyDF3XmDxHCYGQIckxoa5qXpXnBX12pAOm6U/GxxF+4Q4z5zBf4HoekZToUg9WWqMvGWUYIuSeU3Ki7alvgYqmMfiHNWP3nofNiKWcYq3FSVZrgnBPekpvaw2L1TXXYMfcfWKxWFEoNfR7smuWY40ZqLXSNLWlqZvhrxPP5ng7n8D3qapqaLb9AbRGiQBx2HFNT4C9EZZt+1f1r5zkxwPy9yPx4xOlH2D2krr31keHPrPdkk9jn7vViZF35pt3wX/3Hv3mLTaK/1B3Nzv8R1Wl8ITL9/mer1PB/Trmbhay+fwF8+EjVka3y7++L8r1Xzs0r7kKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9f6e726e10f3ba3d58c47a8c8c8307b/e4706/Loxone_13.avif 230w", "/en/static/b9f6e726e10f3ba3d58c47a8c8c8307b/d1af7/Loxone_13.avif 460w", "/en/static/b9f6e726e10f3ba3d58c47a8c8c8307b/c9875/Loxone_13.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b9f6e726e10f3ba3d58c47a8c8c8307b/a0b58/Loxone_13.webp 230w", "/en/static/b9f6e726e10f3ba3d58c47a8c8c8307b/bc10c/Loxone_13.webp 460w", "/en/static/b9f6e726e10f3ba3d58c47a8c8c8307b/d00b9/Loxone_13.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9f6e726e10f3ba3d58c47a8c8c8307b/81c8e/Loxone_13.png 230w", "/en/static/b9f6e726e10f3ba3d58c47a8c8c8307b/08a84/Loxone_13.png 460w", "/en/static/b9f6e726e10f3ba3d58c47a8c8c8307b/5a190/Loxone_13.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b9f6e726e10f3ba3d58c47a8c8c8307b/5a190/Loxone_13.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For testing, we create again a `}<strong parentName="p">{`Virtual input`}</strong>{` in the form of a `}<em parentName="p">{`Button`}</em>{` and connect it to our `}<em parentName="p">{`Intercom`}</em>{`. Save the setting and transfer the program to the Miniserver:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b5e5b22fded2f9bb9d951b6a300640f9/5a190/Loxone_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACVUlEQVQoz22PyW7UQBRF/YWs+Bw2fAkLVoFVUCQEEiFBihJlEUETuwe3u+14tmt2lV3lmlCHMETJUa1e6dx3X9DG6+7mSmA0QDAMFCEwoHpSDFMCEIIQMkankfOBCTFwxmDXIdDDrsMABndVWURhdnpefT0DXVvH4fLkTRJf3S43+6ysmw4iksTxMoqWYQQxLhGoMBi9584GQlICcnQX4XJNh4rhnO1DXKwxyTHKEMrqetM0WwRTTHOcRO3RMTg6xu8+oLfvgyZc9Kuf3XrB0jXdRW10U1UJQLUQkJImy/c/V+uiLghpEGlovscX1+DiGny77D+fBVVZWW0GOnDKjfOq7oo0akHvvRdiQph67zFmjPFxkozLYTbOPxAABK212uh5ng9Twso0yopUa22MEWLy3rVNA3rQFcnl7afT8OP36lLp0VsXlGVJKeWcG6299w7hvsjzslRSaWM4H733lNJpkiRc7MKrqFiuqlvKyWFz3bT3G4SU0nhvIGzzNC8KzoWxduDikOgOnZz3Ss2z0ta435OgqmuC8TwrJUelZwtQkyfr7QZj8ld+wDn/mKAoS4yQlJIRKqbJAtSWSd3VetZaa/6//IRglWxbAJgYp1lJZ2eIYJPHSbzfpVl2h/DhNmft83IWhjjLVN+rrpsR0umdgG3bd3VVEUL/1X7S+SArQhQhlgvLueHccc76fhtv0zRjlI1q9uPIX722Tfs0IngUdf9H42j35aQ4Pe8Xi0EqP3D64qXJi2dkd4//85xzsxJZ9mO32iSbuO16JSWPlmYcn9b+BU+7SKMv+tTrAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b5e5b22fded2f9bb9d951b6a300640f9/e4706/Loxone_14.avif 230w", "/en/static/b5e5b22fded2f9bb9d951b6a300640f9/d1af7/Loxone_14.avif 460w", "/en/static/b5e5b22fded2f9bb9d951b6a300640f9/c9875/Loxone_14.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b5e5b22fded2f9bb9d951b6a300640f9/a0b58/Loxone_14.webp 230w", "/en/static/b5e5b22fded2f9bb9d951b6a300640f9/bc10c/Loxone_14.webp 460w", "/en/static/b5e5b22fded2f9bb9d951b6a300640f9/d00b9/Loxone_14.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b5e5b22fded2f9bb9d951b6a300640f9/81c8e/Loxone_14.png 230w", "/en/static/b5e5b22fded2f9bb9d951b6a300640f9/08a84/Loxone_14.png 460w", "/en/static/b5e5b22fded2f9bb9d951b6a300640f9/5a190/Loxone_14.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b5e5b22fded2f9bb9d951b6a300640f9/5a190/Loxone_14.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We now have a virtual bell button that when pressed shows the live video of the camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f60eecd3c4075cfa349fa2cd536c2830/5a190/Loxone_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADwklEQVQ4y0XS2U4bBxjF8ZGaVCk7GPC+LzPjHQxjvGAcA7bBMRjjZRabJCxhsU1Ik/aiqlRVjZp0k1K1aftKfYO+zb8yvejFd/vTOTqfYHM78fidmK1zfPrZIyamJ5icmWBmbor5uWmWLfO4ZSv+pBN3wIbVbkaOSURXooTjMoFYmI1QkGoiiTcqI+SjUWr5HPloGOfUFAHTAuKiCWlpEdlsZiXoIpcV2SqGySSCJD0eyul1nmzmqGY2KCgKvViSu0yBQiaD8M4p8b1D5L1L5hdflJ+9EX5wSXwIJPg9ledjdpuPSok/Mjv8mdvlr2yJ38IpfpVTfJBT/OSP8U5M8jaY4Ed/HGF2ZorZmUls5kW6rRZ9TaWyXcTntOKxmwm47Yg+J3LIiywFkOUgclgkFBHxiAHyO4/vay+7nZjcToS5mSnmpidw2SzonTZaq0WtvI3PYcFtWboHQz4XUtCLJPoRpRBBOYRPDmEP+UmXingTURZ8bua9HgTT7DRLc9PYzYuk4hGSYZHViEQhvcbj7AY5JcVmZp1CboPNzSz5rTzZrTzKVp5UIU8km8YaljCFAsz6fQjLC7OYF2bx2C3cXl9ydfacm/MzRlcvuDx9itZuonVa6GobTe/SNVTaPY0DtUPv8oJv3r/DnIwzKQaYDgUQLIvz2JYWCLhsNPYrHO1XadVrHNX2OKiWadZrNA+f0Dw6oHHc4KDVpNZqUj4+YrfVZEfrEkvGcYghJqUQgsNswmVeRPI4eTW4YXB+xuvbIdenzzBaTfrjJHqXXk9H7xt0T3q0nvap6xrqzRXfvv2Ov30S/zj8JEURwW1dwm8zE/a6eLJTorZdvL/90hblrU0qpSKV3RLlyi7b1QrFvQq5vQpKtczafhWlUacejVIMSczIEoLfbiHktBILeCgqKbYzCtWtPPlUkuxqgszaCun1VdbT66xuKMQzaaRMGt+GgkNJsZRa4ZNkDCEa5kFYRhBdNmSPnVXRz5cvh7y8umB0ccYXt0PejIa8OH2KoasYfQN1XPekR6Pfo9Y3KPd0dvsGy8oajyIyD6MRhLDXQcznRIkEuTC6nI7XPKxjHB+iHzfuV1Y7LdrdNk21w6Gmsq912VU7FDrHpJsNTMoaD6Ph/8CY30Uy6CYbl3h1fc7d5RlvBpe8Hlzx+fCau9ENdy+HDEcDrm5HaOfPqRs6Zb3L4fjFvv6K+bXVe/DBGBxjKdHLZiLM4Fmf62cGlycaZ3qH057K857GSV/HOBkvbHDU06jpKjtqm8qJgToaML/+P/gvaywB6uKN0xoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f60eecd3c4075cfa349fa2cd536c2830/e4706/Loxone_15.avif 230w", "/en/static/f60eecd3c4075cfa349fa2cd536c2830/d1af7/Loxone_15.avif 460w", "/en/static/f60eecd3c4075cfa349fa2cd536c2830/c9875/Loxone_15.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f60eecd3c4075cfa349fa2cd536c2830/a0b58/Loxone_15.webp 230w", "/en/static/f60eecd3c4075cfa349fa2cd536c2830/bc10c/Loxone_15.webp 460w", "/en/static/f60eecd3c4075cfa349fa2cd536c2830/d00b9/Loxone_15.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f60eecd3c4075cfa349fa2cd536c2830/81c8e/Loxone_15.png 230w", "/en/static/f60eecd3c4075cfa349fa2cd536c2830/08a84/Loxone_15.png 460w", "/en/static/f60eecd3c4075cfa349fa2cd536c2830/5a190/Loxone_15.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f60eecd3c4075cfa349fa2cd536c2830/5a190/Loxone_15.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7fb955f50067b8f1f641cc70940c86de/5a190/Loxone_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "105.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD1ElEQVQ4y62T609adxyHf7ScAwcxcvBQELwd8EJRLu0RUTgoCgOhCiqoXGa5yAG5VxEv6NDO4mWdUmtTXeY0nVObzi0x3Zbdsi5LmrQvmv1Ly7JsaTrfbc/b7/N8332ASCRCURQAQKPRwN/QaDQYhmAYZjAYMAy/ffoLGIZxHAc4jmMY9k5cxi5raZHJ5Qq5XN7cLGWxWO/ETCZTKpWCS0EQpBLDKjGMi/4JC0HA/w8TQdjlFShfwGAi9KtXIQiCGAyITqdfuQJDEBNmsFhsBCnDeDyMh3E4nHI2mwFBDAii0yFg0fZGMsup+48t1mF1g1Sn6+gdtrVJm1QikUEhd2g0GzsPt7d3fn3zemdvdzqeWk2ltyZjLpdPresCJoI0mZ1dg94eg0XXouwxkGa3XXO9oaNRYlS2jnaqS8X8ejaxXvzgtv99V5+l4PE+GA9IibZupRJY23XpcIyau2cy2kiZwqDvNAxZOlUyslVGSvCAyXB8+tnmaqG4WggGvbGg78Gd5Li+SySp65NIwM06WcgxvHX8VWQq39Eg7dZqSLupTdagxms7aoV+i+nox+8eHh9ufrI1vzK7e7T/xdnnA/02grgxO+EHrWLZiM2R3yr5J9Na+Q1SQ1i9A3q1UtMk0Unq7kwELt78/vSn7y9evtg/e3LyzcXh2TOj2WazWfdLJUC0qsjrimQun1lcU1TXyKr5rdJ6eY1AIbxGCLDMROj4+fOjZ09PL75eWb+3tlEszOZ0ZLfb59veuA/0pLbfand5AiEqMeYYGDL3Ooz6IVPXoLHLbtAFPc7FwlJ2Ib+yvp5byEcpigoEiXaNfdh5/OkhIDRqx3jEMxGPZXP54mqusBRKRcPxUCjij2eo1a3ixwd7Hx3s7Z+fn//28vTbHw5OvrQMOruNpu2NbaDuJJ2eYDCWTuVy2fw8lYz7o1R6JpPIRBPT8Ug6ulbaKO2VltfuphfmHp+cPXpyah/zqtrUC4sfgpHApDsYTWRns4v5cDLu8oz4qdBEctJoNQ25R245h9JLS7mV5bmlmdn8dCyd2Nx95AmGVe0au2sUeEMxXzianMklZ2a8gXGX1zk67m7Xa1mccqfP/d6ATant6Lb2DQw77hYLc/PZ9NSU3TVGdGrN/beAsLqmth6vw/Hq2poqkbASq8T41yp5PJgJC4RV/CoBysO4PAzlcsUScXNTU5WwmoNyOSiXL6j6b6ui/QsAAIOJ3PYHVDcJsaSRoqIVHPRt85/wkl8AgPIKzs+/vPAHwz0m86tXr0U1dZfbl4+cxaoXN/IFQhTjiRuakDL2pd4fuVURl1lSPcsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7fb955f50067b8f1f641cc70940c86de/e4706/Loxone_16.avif 230w", "/en/static/7fb955f50067b8f1f641cc70940c86de/d1af7/Loxone_16.avif 460w", "/en/static/7fb955f50067b8f1f641cc70940c86de/c9875/Loxone_16.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7fb955f50067b8f1f641cc70940c86de/a0b58/Loxone_16.webp 230w", "/en/static/7fb955f50067b8f1f641cc70940c86de/bc10c/Loxone_16.webp 460w", "/en/static/7fb955f50067b8f1f641cc70940c86de/d00b9/Loxone_16.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7fb955f50067b8f1f641cc70940c86de/81c8e/Loxone_16.png 230w", "/en/static/7fb955f50067b8f1f641cc70940c86de/08a84/Loxone_16.png 460w", "/en/static/7fb955f50067b8f1f641cc70940c86de/5a190/Loxone_16.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7fb955f50067b8f1f641cc70940c86de/5a190/Loxone_16.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now all you have to do is replace this virtual bell with Loxone physical accessories. In the following example we have connected the `}<a parentName="p" {...{
        "href": "https://shop.loxone.com/dede/bewegungsmelder.html"
      }}>{`motion detector`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://shop.loxone.com/dede/loxone-touch.html"
      }}>{`Touch Control Element`}</a>{` with the `}<em parentName="p">{`Intercom`}</em>{`. If you operate the control or be detected by the motion detector, the ringer will be immediately resolved and all displays running the Loxone software will show you the live video of the embedded camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/62a43f7ef2bb6680478cc450c76c0541/5a190/Loxone_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "86.5217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB50lEQVQ4y42RW4/aMBCF8///Ty9S1W1Vla4WRYVlYWmBkMR2rnYS4viSGF+qLCplH7pw5Id5mM9n5ozHOc/zvGkaZ5211hjjnGuaJs3ysbZGX8iYV8+jtN2HuyzL+35wF6qbg9bGvSmvY4ff22WwC4UQzo3mLzKEVBDCcB9iXNSkSECKc2KO5tzzAotDEP8qS6KUOn+ptYZJ2nHRdp3sB0yLTbre5mtIQ22P/5y57LbBMwSwKIqqqsqyrOuacw72EYlBBRHLiihf+un9t92nH+BL2aacsaZphBCeUn2JEUIIAiik7PteCMGljH/On959mL3/uLv7nD/NcdXgElNKGafDMEjZK6U8YywpMAIIInSeXBuNYmAZd1xYxpzWfye1rwLT+hhG6ziOyhKf7nTaGSXpKZW30h7h5DGKojgClHYnYIRRasc7j9f/L2ysgSjYBwEuMes6ay7gq87GGAgBQBBXNWWcSTEc1a2wc04NA47CdLNp00wSIuta9cOt8CBEMZ0C34dTP53NqvlC9T1Ks5vgo1JktSofF/l8QVbPzXZXhRHYR1dQ5zzrnB6GzveTyaR4mKLvk2Ryj+6+wsXyOuycE4IjBOMYYEwYY0JKxjlAyfWxTx2cMdpS2racsbGmNM8LY67AfwBfTM6kSaMQjwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62a43f7ef2bb6680478cc450c76c0541/e4706/Loxone_17.avif 230w", "/en/static/62a43f7ef2bb6680478cc450c76c0541/d1af7/Loxone_17.avif 460w", "/en/static/62a43f7ef2bb6680478cc450c76c0541/c9875/Loxone_17.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/62a43f7ef2bb6680478cc450c76c0541/a0b58/Loxone_17.webp 230w", "/en/static/62a43f7ef2bb6680478cc450c76c0541/bc10c/Loxone_17.webp 460w", "/en/static/62a43f7ef2bb6680478cc450c76c0541/d00b9/Loxone_17.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62a43f7ef2bb6680478cc450c76c0541/81c8e/Loxone_17.png 230w", "/en/static/62a43f7ef2bb6680478cc450c76c0541/08a84/Loxone_17.png 460w", "/en/static/62a43f7ef2bb6680478cc450c76c0541/5a190/Loxone_17.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/62a43f7ef2bb6680478cc450c76c0541/5a190/Loxone_17.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The output on the right side of the `}<em parentName="p">{`Intercoms`}</em>{` can in turn connect other devices that are to be triggered when ringing.`}</p>
    <p>{`To the output on the right side of the `}<em parentName="p">{`Intercom`}</em>{` you can connect further devices, which are to be triggered during the ringing. In the example we have connected the orientation light of the Touch control. The end result is:`}</p>
    <ol>
      <li parentName="ol">{`You press the virtual bell button in the WebUI `}<strong parentName="li">{`OR`}</strong>{` you click on the Loxone Touch control `}<strong parentName="li">{`OR`}</strong>{` you trigger the Loxone motion detector and the `}<em parentName="li">{`Intercom module`}</em>{` is activated.`}</li>
      <li parentName="ol">{`When activated, the miniserver WebUI and the Loxone Smartphone Apps will automatically display the video image of the camera and play a ringtone.`}</li>
      <li parentName="ol">{`As an example for another action we have connected the orientation light of the touch control with the `}<em parentName="li">{`Intercom`}</em>{` and see that it is activated when the `}<em parentName="li">{`Intercom`}</em>{` is activated (e.g. you could install a gong here).`}</li>
    </ol>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "integrating-the-alarm-output-relay-of-the-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#integrating-the-alarm-output-relay-of-the-camera",
        "aria-label": "integrating the alarm output relay of the camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Integrating the alarm output relay of the camera`}</h2>
    <p>{`The `}<strong parentName="p">{`most`}</strong>{` INSTAR IP cameras have an alarm output:`}</p>
    <ol>
      <li parentName="ol">{`For indoor cameras (such as `}<a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8015_HD/"
        }}>{`IN-8015 FullHD`}</a>{`), this is usually port 1 and 2 of the four green connectors on the rear panel of the camera.`}</li>
      <li parentName="ol">{`For outdoor cameras, you will usually find the connection on the cable harness of the camera.`}</li>
    </ol>
    <p>{`We would now like to use this alarm output to report an alarm on the camera in the `}<a parentName="p" {...{
        "href": "https://www.loxone.com/dede/kb/inbetriebnahme-miniserver/"
      }}>{`Loxone Miniserver`}</a>{` and trigger a reaction there. In the following example we take the `}<a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-8015_HD/"
      }}>{`IN-8015 Full HD`}</a>{` indoor camera and connect it to the `}<a parentName="p" {...{
        "href": "https://www.loxwiki.eu/display/LOX/Potentialfrei+und+potentialbehaftet"
      }}>{`digital input (potential free)`}</a>{` of the Loxone Miniserver:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e4d6cf658cc5e7c325fed4343b81d869/5a190/Loxone_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB2klEQVQoz42Sz2sTURDH8wf4D3gR/4OCPYggCBasQo/2oAgetAV7sI1eBKFoD0Jo8ZAIUiUpFVRqQ6strSQkqRZCao2RbcivZptmm82P3bZLQtN9m7w3byRZ06X1UL8MzDDzPsx7M8+GHemEJJOpWCwWTyQP6od4XICcAQAwYC1vJm2IyDlHxIIsBwKBb6GQf3FxO51GSrmuIyFISCugFP+R7SiqavtarborivuCUBWzvFJBRUFF4arKZDn6OxLOZ0pb0dJGOBFPmf0suCDli5WyLAjFzc2dUtEsI+KX1PTE6uO7H2/2TV0fdl8cdnb1TVxYk33HYIPoDWBUUaimkYZxBDvDT576b72MDD1bvj0eeDTqv3ffe3khMW3BAJDPb2fEbO7nem5DkAoyaz8SOLz5NeaIPHD8GBj7OjQy22/39t/xXPkce2fBlFJVVbVabS+d3ctJZUWhnQm9mLH3jHZffX6p137+2sOzN0bOdQ+eeR+etKZNKd2RtpaXvOvzrng0SBoNoh+aFy8XJXfw0+vgwtoHz+qr8e9OR8jt2tVUq7NhGCsh39SkyxeZ86y8FTNivV7H0/QXZp3VAyAH3mw2GTCzxAEoMGp+j5anjNGTq2ofA0Tetv/SH7WmQoeFZbafAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e4d6cf658cc5e7c325fed4343b81d869/e4706/Loxone_18.avif 230w", "/en/static/e4d6cf658cc5e7c325fed4343b81d869/d1af7/Loxone_18.avif 460w", "/en/static/e4d6cf658cc5e7c325fed4343b81d869/c9875/Loxone_18.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e4d6cf658cc5e7c325fed4343b81d869/a0b58/Loxone_18.webp 230w", "/en/static/e4d6cf658cc5e7c325fed4343b81d869/bc10c/Loxone_18.webp 460w", "/en/static/e4d6cf658cc5e7c325fed4343b81d869/d00b9/Loxone_18.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e4d6cf658cc5e7c325fed4343b81d869/81c8e/Loxone_18.png 230w", "/en/static/e4d6cf658cc5e7c325fed4343b81d869/08a84/Loxone_18.png 460w", "/en/static/e4d6cf658cc5e7c325fed4343b81d869/5a190/Loxone_18.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e4d6cf658cc5e7c325fed4343b81d869/5a190/Loxone_18.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the `}<a parentName="p" {...{
        "href": "https://www.loxone.com/dede/produkte/loxone-config/"
      }}>{`Loxone Config`}</a>{` Software, we can now drag the `}<strong parentName="p">{`first digital input`}</strong>{` onto the work surface and - for testing - connect it to the orientation light of the `}<a parentName="p" {...{
        "href": "https://shop.loxone.com/dede/loxone-touch.html"
      }}>{`Touch control`}</a>{` we had previously used:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/26cbe4525fc9eb06e7a40df0a71c5952/5a190/Loxone_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.347826086956516%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABeUlEQVQoz3WSy47UMBBF+/9/gAX/wSxgxxLoXsBipJmeuJM4sWPHjyQVl+MXSrcAgcJR7cqndFXl0/X1yjnXWltrnXNaq7qpjDHee0QMISg1NpRXjdB2gRUW+FMn1rN1XVNO8zznnVR3z1KOpZSccykFAKSU5YiTGAatNSJ6xLucm5ZwxmKMjxfOoRDy96y/5K7rjDEAMHDunMs5a60ZZ+aOtdM0z3JUx/LtRoSQKSVE52DZNs84JYRMdsI7ywJyHI9lQkjf9wCglAJYYwy0v7Vt673/FdsJ+Z/YUsht25xztG3X1ZVSKK8pYxi2bfMpBEQvlc6HCyOEkIogYowxhhhzMrK/ni/PXy4t5YOyYjSTkCWEA/n15YXSDgCcc977Lcbo4Pz564en86fv7OnH8PFS63fv/beLRXwc2FqLiLu8LIsxFtHnnB7EFKWW4yjkwJTgA+unaS6lhBD27h4vppR2+Z8kOecYQ9c1pCJv5Ebq5q0i1trDT/ITFtx1Y/WfhNAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/26cbe4525fc9eb06e7a40df0a71c5952/e4706/Loxone_19.avif 230w", "/en/static/26cbe4525fc9eb06e7a40df0a71c5952/d1af7/Loxone_19.avif 460w", "/en/static/26cbe4525fc9eb06e7a40df0a71c5952/c9875/Loxone_19.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/26cbe4525fc9eb06e7a40df0a71c5952/a0b58/Loxone_19.webp 230w", "/en/static/26cbe4525fc9eb06e7a40df0a71c5952/bc10c/Loxone_19.webp 460w", "/en/static/26cbe4525fc9eb06e7a40df0a71c5952/d00b9/Loxone_19.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/26cbe4525fc9eb06e7a40df0a71c5952/81c8e/Loxone_19.png 230w", "/en/static/26cbe4525fc9eb06e7a40df0a71c5952/08a84/Loxone_19.png 460w", "/en/static/26cbe4525fc9eb06e7a40df0a71c5952/5a190/Loxone_19.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/26cbe4525fc9eb06e7a40df0a71c5952/5a190/Loxone_19.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If we now trigger an alarm on the camera via the `}<a parentName="p" {...{
        "href": "#example-1-triggering-an-alarm"
      }}>{`Loxone WebUI or App`}</a>{` we want the camera to send a pulse via the `}<strong parentName="p">{`alarm output`}</strong>{` to the `}<strong parentName="p">{`digital input`}</strong>{` of the Loxone Miniserver. We can set this in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`alarm actions in the WebUI of the camera`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cc6b88519c58996a60024f1dfa10d318/5a190/Loxone_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACrUlEQVQoz02R60tTcRjHzz8S9Q/0onpR0Jug7I2kpklWLwo3IdBdVPJ4NvAoXmLVvBAEgUu3s7ORgWyO6IIRFNUbNWWhFRU4d9/O3M5t5/yucbaKvnzfPA98+D4Xxu0eHHY7b4/M3JiK94yv9kysdo/HrliONxz75y4+dn0yfvNu4tS59mNHj7S0tDDsKDc8NPjgUejNN+NFUn75RUnsyGvbtdhGObYpxbdr8e3aWsPxz9W1LWn9q97nYk+eON7VedmCB5yuiChSSggG9K/ypcphTaYUUWTQP30iVyVoaNMzE2fOnr52tZsZGeUcTpcQFgGANVlRVBVhAhCJfsisJ8sAgpqs1A0TE4owqR5WTKM+eXf6wsXztt5bzB2WG3C4wmKEEKyqqmEYVgQhuWxaKuTBQUZPHYBsDmRzMF/4vvMrs7s/N+tv62jvs9sZj8cz4HSJYoRgrGlaE6aElCTpsFiE2VwdgHq9DiFACI/FMo+f7c7f87W2tdttdsbr9fY3xsbYSjZNs5lcLpeqFclMpfX9lHGQJoUiyhf2NvYOkj/9fn9rW4et12bBDqc1NqXUNE2McQOmqVy5WJEhQpqm6bre7Eulgq4p/rmF1ksdtl57E3aLYgRCqKgqhJBSijC9/zwd/VSiFBumaZgmJsTapVRWVXV2fqG1vdNm62NYjut3uELhCMZYUVRd1xswcQs/Hr7OUIpkRYHAehWhVK3XAUKhkDA0NOz1eJqvcgthESGoKIqu6wRjiPDi22xis0wpMSGuqKCiAqNaA6US0fRINOr1esd5nmFHPQ6nKxKNNu9E/1OzKsgwtlVNJNXsq/eVwLL+7uPTlRWO4yZ4nuE4jmXZqenpQCCwuLgYCASCDT1ZWl5aXg4Gg6FQMCxYDglCMBwOCYLP5+N5nh8b+w0+yUph3tcVawAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc6b88519c58996a60024f1dfa10d318/e4706/Loxone_20.avif 230w", "/en/static/cc6b88519c58996a60024f1dfa10d318/d1af7/Loxone_20.avif 460w", "/en/static/cc6b88519c58996a60024f1dfa10d318/c9875/Loxone_20.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cc6b88519c58996a60024f1dfa10d318/a0b58/Loxone_20.webp 230w", "/en/static/cc6b88519c58996a60024f1dfa10d318/bc10c/Loxone_20.webp 460w", "/en/static/cc6b88519c58996a60024f1dfa10d318/d00b9/Loxone_20.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc6b88519c58996a60024f1dfa10d318/81c8e/Loxone_20.png 230w", "/en/static/cc6b88519c58996a60024f1dfa10d318/08a84/Loxone_20.png 460w", "/en/static/cc6b88519c58996a60024f1dfa10d318/5a190/Loxone_20.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cc6b88519c58996a60024f1dfa10d318/5a190/Loxone_20.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The `}<strong parentName="p">{`duration in seconds`}</strong>{` indicates how long the alarm output relay remains open after an alarm. The 5s set here mean that the orientation lamp lights up for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`5s`}</code>{` after an alarm and then goes out again.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "integrating-the-alarm-input-of-the-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#integrating-the-alarm-input-of-the-camera",
        "aria-label": "integrating the alarm input of the camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Integrating the alarm input of the camera`}</h2>
    <p>{`We can now look at the opposite way and address the alarm input of the INSTAR IP camera via the `}<a parentName="p" {...{
        "href": "https://www.loxone.com/dede/kb/inbetriebnahme-miniserver/"
      }}>{`Loxone Miniserver`}</a>{`. Therefore we connect the first `}<a parentName="p" {...{
        "href": "https://www.loxwiki.eu/display/LOX/Potentialfrei+und+potentialbehaftet"
      }}>{`digital output`}</a>{` of the miniserver with the alarm input of the `}<a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-8015_HD/"
      }}>{`IN-8015 Full HD`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3f8730239dc2e71c9e1932f34e3b69ce/5a190/Loxone_21.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB0UlEQVQoz31SXUsbQRTND/AP9KX4DwptQZBCoQXbgm/ig6X0pR8UH9T4JAhtyUMh1JaqUJuChhT0wQ9QSysJi6miTYSagiYm1bXZ3e4mzQY3iuJu9s6dK2twtxHpYWAuZ+bcc5kzPjrDsWlubWVTqVQ6nT48PKJ6MESGyJEhY4hYI31ExDknoj+qKgjCt3g8FovlpbzLOwVxugg+t9qvGIZhlEolXS/tVypue06cIf5UxUQ+V9hdL2x8z2xma309saLImqYpiqyqqiLLru1cJjyw5H840dYavts91tQ9dKV14GpSjdaJLcus2lUAsAFM03RHHVrt6492vE10vly4/1rofR579Hj6xnwm4okRUZKk7V85cWdHFEVZVoCBw3P8uB4IJp4F154EFjp7ptr90+0Pxm7Opj55YgDQ9bJhVHR9Ty/vFYsFYKx29GrSf/vF9VuB5ju9jS1dl+71XL72tGF8NeS9NgAo8u7Xz5NrQji38qUqSVb+N2ka/S0WfyRHxwdHIiPJD++W379ZGgwujg6XDd1ztiwrvhgNh4ajiZmIEBKz20cHB4TorItj+icqdha9cxm5bdvMjYpzQAZ4+k2cHRiD81ER8VM9p/941TufAGN0Q5/mPQj0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f8730239dc2e71c9e1932f34e3b69ce/e4706/Loxone_21.avif 230w", "/en/static/3f8730239dc2e71c9e1932f34e3b69ce/d1af7/Loxone_21.avif 460w", "/en/static/3f8730239dc2e71c9e1932f34e3b69ce/c9875/Loxone_21.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3f8730239dc2e71c9e1932f34e3b69ce/a0b58/Loxone_21.webp 230w", "/en/static/3f8730239dc2e71c9e1932f34e3b69ce/bc10c/Loxone_21.webp 460w", "/en/static/3f8730239dc2e71c9e1932f34e3b69ce/d00b9/Loxone_21.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f8730239dc2e71c9e1932f34e3b69ce/81c8e/Loxone_21.png 230w", "/en/static/3f8730239dc2e71c9e1932f34e3b69ce/08a84/Loxone_21.png 460w", "/en/static/3f8730239dc2e71c9e1932f34e3b69ce/5a190/Loxone_21.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3f8730239dc2e71c9e1932f34e3b69ce/5a190/Loxone_21.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the `}<a parentName="p" {...{
        "href": "https://www.loxone.com/dede/produkte/loxone-config/"
      }}>{`Loxone Config`}</a>{` software we now have to add a `}<strong parentName="p">{`button module`}</strong>{`. Make sure that you are on the `}<em parentName="p">{`My Project`}</em>{` tab (1) and then select the button under `}<strong parentName="p">{`Insert button`}</strong>{` in the Operation category and place it on the desktop:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9c7fe3ac86c2ec9c52b49795281de63c/5a190/Loxone_22.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACaElEQVQoz22RSY8URxCF65/7ZvEz7Jsl4GCNLHMBRjCDhNGs3TM0vdSS+1YZmZW1ZDa1oOYEZp4iLk/6pBcvMr65FR/eNVJ0oU2xTynGeNoYY9M0WitjdExDTMMQu3Q89s5ajKyF2smMY4TLPSkKtt8bgWswnHFGqZRSayWl0FpZkFxVOXpgjIrdI/70VhwehP+c1a4Mbe08cg1pGuoAOY9DoN7j8N2pbYnxTvBSKxxao9nm1T8fzt6Lt2e3WVHkbdt23Wli7K01XBKtpPfOe+fAleiweryqKsQ509ptD5d/v/n31Ue4uNhmlFDBhfc+xrR8l9EyP+Rg3TRN0zgLxW9WH6sSSamMtlTnBg7LlIahyRxACCHFCADjOC7L0rctQRhjghCmlCFSrT9fVSU6FSE0lQci9kN/1MZky8+alyUN/QlGuChLQijC5Wp9xahwYLswYLXZ4+vQ9ADwCzzPYGqMcF0b5xyAL3Gx3a8Z5d67vovc5BV/7Lv4BDxNMxV5WRUALqVTC0yw9eNdnhdSyeD7nKwKeh+Hr9ba7OfM87IstqEl2iFECCEhBKEkJYWWpGvqY98RtTrQ6xMMNvv/yfNiPCmqHSUMAFJK++2Xs9cXr//D51f48s5e3t8U7Gl4maaRij1CCADm+RRkc3/37M/zZy/E78/Jb3+xP17eWlW27VOFTfNECOKMh9DGGOd5wbRcXZ+jzVpVW0cQqW5Ktm5ca5541Tz3Q8841do0TRNjlEpxpbR1vu26fmSQV+Khb6NzkKUfFGOcpimlRDBCCCut29ACuHEch2FIKR7TxOvdtvrkodVGfwN3Oj0R/C1dRAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c7fe3ac86c2ec9c52b49795281de63c/e4706/Loxone_22.avif 230w", "/en/static/9c7fe3ac86c2ec9c52b49795281de63c/d1af7/Loxone_22.avif 460w", "/en/static/9c7fe3ac86c2ec9c52b49795281de63c/c9875/Loxone_22.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9c7fe3ac86c2ec9c52b49795281de63c/a0b58/Loxone_22.webp 230w", "/en/static/9c7fe3ac86c2ec9c52b49795281de63c/bc10c/Loxone_22.webp 460w", "/en/static/9c7fe3ac86c2ec9c52b49795281de63c/d00b9/Loxone_22.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c7fe3ac86c2ec9c52b49795281de63c/81c8e/Loxone_22.png 230w", "/en/static/9c7fe3ac86c2ec9c52b49795281de63c/08a84/Loxone_22.png 460w", "/en/static/9c7fe3ac86c2ec9c52b49795281de63c/5a190/Loxone_22.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9c7fe3ac86c2ec9c52b49795281de63c/5a190/Loxone_22.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then pull the first `}<strong parentName="p">{`Digital output`}</strong>{` onto the work surface and connect it to the output of the button:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b77d062cc383fe0385237f20b6ebef3e/5a190/Loxone_23.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABGElEQVQY04WPcUvCQByG9/2/SyAEEkWRf6iYmVqmpHO7+91dt02v2+ZG2+53u5DIoIIenn9feF4vTqIsy4qyLIoizw95nh+y/FWKu8VkNN1QsU/TVGmt9NunWqcnvSihgjFOIdW6PO7z7L0KBEtLcH/Rfumc87LJlF1eke6Ffpg552zduIDoxXNtENFaa9vW0oP/pMaP6j7QS2uMQ+ssOkTPlCXf+tvVKgIW73Y7xtOzTnzeXfub+WzuBz6XJNgvx6Q/JP1Vv6N6N7I3DK4H8e3AQ9smUoYhkTICAGCMKyWUrqoKjxwDTq0ttrapsW5M3WBjPERMhCBhyIABBUIpo0BCYoz5dfYn32POOQBQCoyzl/WmaYz7jw9AUoZgfB0S0wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b77d062cc383fe0385237f20b6ebef3e/e4706/Loxone_23.avif 230w", "/en/static/b77d062cc383fe0385237f20b6ebef3e/d1af7/Loxone_23.avif 460w", "/en/static/b77d062cc383fe0385237f20b6ebef3e/c9875/Loxone_23.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b77d062cc383fe0385237f20b6ebef3e/a0b58/Loxone_23.webp 230w", "/en/static/b77d062cc383fe0385237f20b6ebef3e/bc10c/Loxone_23.webp 460w", "/en/static/b77d062cc383fe0385237f20b6ebef3e/d00b9/Loxone_23.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b77d062cc383fe0385237f20b6ebef3e/81c8e/Loxone_23.png 230w", "/en/static/b77d062cc383fe0385237f20b6ebef3e/08a84/Loxone_23.png 460w", "/en/static/b77d062cc383fe0385237f20b6ebef3e/5a190/Loxone_23.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b77d062cc383fe0385237f20b6ebef3e/5a190/Loxone_23.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now use any actuator or sensor as an input - as well as virtual inputs that can be operated via the Loxone App or WebUI. In the example we have - as before - connected the `}<a parentName="p" {...{
        "href": "https://shop.loxone.com/dede/bewegungsmelder.html"
      }}>{`Motion detector`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://shop.loxone.com/dede/loxone-touch.html"
      }}>{`Touch control`}</a>{` as well as our virtual bell button to the input of the button.`}</p>
    <p>{`If one of these three elements is now triggered, a pulse is sent to the alarm input of the camera. We can now activate the alarm input again in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`Alarm actions in the camera's WebUI`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c90ce18c57e7e6753278474945960886/5a190/Loxone_24.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACjklEQVQoz02Sy2sTURTG5y8RxLULRRHc2YILa2rAWopubBJRmkcbC3nMGGhoF6WmpFLTIuJC6UKTzEwq2KJWChYXoi1oESyoVFuTNDNp5n0nd+5DZ1JKPz4ulwM/vnPPuczwSHx0JHY7OXV94uVAVhjIitfGKp4XPVcO3TdWuTGxeHNy6UzX1RPHj13o6maSqfTd+Ej+8fOVrfarL8rSplbZUMQNRfgkCZ9l0bt3LKwrlXV55Tu4M5o5feqk3+9nkmk2HI0JPE8pxahNOyKk3miqqkYposim2OlUDaVJIMhNT547f7a/v89NDkdjxVLZQVjVdN0wMSYQ4YX3u+82mxBCVdOB3caEIkwkuWlZYGr6fvfFrkBgkEmk0kORWKnsJhuGYdu2F0yquztNWcIYt9ttjDFCiBCi6zpCqDA357vSGwyFmDTLDkViZa9tXdcPYEoakqQoquM4lmkCACCEhJD9/X0bgNlCoeeyLxAMMizHecllSqllWRDCTrIkSaqqOAgB2257RUqpqmkQOh7cGwiGGI7jwtGDtgEAGOMOvFuX5JaKILR0HRgmhpAi1Kz/BboyW5jv8fkDwVsuHInGeF7AGBuG4SBEqTub3HK19KFGfm+D+h6o1pAsY1kW326tvvn2cObBJZ9/cDDkrurowCwAOvDwws/55W28/Uup1mxJorqONX128cdC6euLZ0+H43GOu+dOOxxxV4UxVlXVNE1KCHRQfnmH/9ighgZbiim3TKnV3muQvRpVlXKpxHHs+Pg4k0qzkWhMEEX3k3gPPhQh7lnTUGldFTatP6/X5EdPzNU1XhCSyVQ2m2VYlk0kErlcjuf5YrFYLpdFT7zgSvR8RHxFFGfy+Uwm8x/+B8OqThG7Nzy6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c90ce18c57e7e6753278474945960886/e4706/Loxone_24.avif 230w", "/en/static/c90ce18c57e7e6753278474945960886/d1af7/Loxone_24.avif 460w", "/en/static/c90ce18c57e7e6753278474945960886/c9875/Loxone_24.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c90ce18c57e7e6753278474945960886/a0b58/Loxone_24.webp 230w", "/en/static/c90ce18c57e7e6753278474945960886/bc10c/Loxone_24.webp 460w", "/en/static/c90ce18c57e7e6753278474945960886/d00b9/Loxone_24.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c90ce18c57e7e6753278474945960886/81c8e/Loxone_24.png 230w", "/en/static/c90ce18c57e7e6753278474945960886/08a84/Loxone_24.png 460w", "/en/static/c90ce18c57e7e6753278474945960886/5a190/Loxone_24.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c90ce18c57e7e6753278474945960886/5a190/Loxone_24.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Note that the relay works in the `}<a parentName="p" {...{
        "href": "https://www.loxone.com/dede/kb/inbetriebnahme-miniserver/"
      }}>{`Loxone Miniserver`}</a>{` `}<strong parentName="p">{`N.O.`}</strong>{` (`}<em parentName="p">{`normally open`}</em>{`). If you switch the input mode to N.C., you would receive a continuous alarm accordingly! Accept these settings and trigger one of the 3 inputs (motion detector, touch control or the virtual bell) and you will see an alarm input in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/Log/"
      }}>{`Logbook of your camera`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/078cd4a5ffd0bdf4b19e01948dbe4970/5a190/Loxone_25.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC+0lEQVQoz3WSS08TURiG5+e4cGEQpVCwU1p6gbbIxQSJK6MLIo06l3NOIWCh5drphWtRLiZGA2KIYYEhYCURBBNTSoV2Op2ZTqEWCLDAoksTMy0EMHHyZDKL8+R95zsfZr5dVVldW1lda6mqMZgqjZbqckt1qc6Ia/Qqje4y+hy41qAuM+JaPUaSJMw+BEG8m34rxtl4LCLynCTGt5OJnSy5j2RCEHlO5DmBY3mOXVyYx2iaRgghG6IoYmIm8Fn6s8T9XoxkAls/V9njL9HjNTazGsussZkV7leAzXxiTz6ymaXYyeRCEKOhjQaQBKgJknccc1Wjh3UvUiZv4l7L8oOGN43U+8dg1gpmKdvsfTh/tXnjWkvoCgzWjwgPfQvYUwrQABI0hDRR3zlXM3ZUN/qjsl/S90aLn30zMRGTO2rxsFV9XIUnrmgLKx0bec1B62sJjC5hJA0oOdnWBKn6zg9lgweG/pTWva1wRPNa1ws72aKumLIrVtwTxxlB2RFROsN5zesN4wLxPIBRAAGIIGpqluU5/fBBxWCq2BnNbw0V2EO3ulhVN4f3cGpXXOeTdN6kzpfIbw0/einCsWWMoCAFICX/s5yckwsdkestQUV7WNXDqXo4vDeudvHGgRTu4vU+qcQlWafStokVjKQhdTqwi/LWjdZ1pXMT743nTDXD63xJ40CqfCil7BWtk2k0vowBiGgAAbRdrF3o2Cqwbygd30ucW2qGVzOCmhE0bqHMK+l8UmG3YJ1Ko/EVDCJ0Vps8T27fLLCHito2SpybObPULWjcosYjlnkT5zINkXxb8FJtRVv4pj2ksIeKHWHcxeNybaGUETSehNabUHQLjZNpeWCnMrpUW8OI8lGG17lFfd+2TP+OIUv5wI7KnXwyvd/y6qtcW14QgGiKqOuYM2Rls3/PMrJn9u+Z/fvZ9zmWkT1t/y45c+ScWpcHRtLykoDshuWSK4bSFUNpU47h3YuY/buavjQ5c+iYzMoUDSmIAEXcPUv+R/if/BcjIhWzFTJ5+wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/078cd4a5ffd0bdf4b19e01948dbe4970/e4706/Loxone_25.avif 230w", "/en/static/078cd4a5ffd0bdf4b19e01948dbe4970/d1af7/Loxone_25.avif 460w", "/en/static/078cd4a5ffd0bdf4b19e01948dbe4970/c9875/Loxone_25.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/078cd4a5ffd0bdf4b19e01948dbe4970/a0b58/Loxone_25.webp 230w", "/en/static/078cd4a5ffd0bdf4b19e01948dbe4970/bc10c/Loxone_25.webp 460w", "/en/static/078cd4a5ffd0bdf4b19e01948dbe4970/d00b9/Loxone_25.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/078cd4a5ffd0bdf4b19e01948dbe4970/81c8e/Loxone_25.png 230w", "/en/static/078cd4a5ffd0bdf4b19e01948dbe4970/08a84/Loxone_25.png 460w", "/en/static/078cd4a5ffd0bdf4b19e01948dbe4970/5a190/Loxone_25.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/078cd4a5ffd0bdf4b19e01948dbe4970/5a190/Loxone_25.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The alarm input then triggers all alarm actions that you have activated in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`Alarm actions in the camera's WebUI`}</a>{` - in the example above, the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarm server`}</a>{` was contacted.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "use-the-loxone-miniserver-as-alarm-servertrigger",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-the-loxone-miniserver-as-alarm-servertrigger",
        "aria-label": "use the loxone miniserver as alarm servertrigger permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use the Loxone Miniserver as alarm serverTrigger`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Please note`}</strong>{` that there is a new `}<a parentName="p" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing/"
        }}>{`Alarm Server API for WQHD 2K+ cameras`}</a>{`! The individual steps below are still valid. Only the query is now called `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&trigger`}</code>{` instead of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&active`}</code>{` and the numerical values have changed and now allow the evaluation of multiple, simultaneous alarm triggers!`}</p>
    </blockquote>
    <p>{`All INSTAR HD and Full HD cameras have an `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarm Server Function`}</a>{` which can be used to contact an external alarm server via a web request (`}<em parentName="p">{`HTTP GET`}</em>{` to the `}<a parentName="p" {...{
        "href": "https://www.loxwiki.eu/display/LOX/REST+Webservice"
      }}>{`REST API of the server`}</a>{`) in case of an alarm on the camera.`}</p>
    <p>{`As receiver for the alarm server request of the camera we can use in the `}<a parentName="p" {...{
        "href": "https://www.loxone.com/dede/produkte/loxone-config/"
      }}>{`Loxone Config`}</a>{` software a `}<strong parentName="p">{`Virtual input`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cd83bdc1b2db66bdb031130561ffe888/5a190/Loxone_26.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABk0lEQVQoz22NQYvUMBhA+/9/gwcPgh6FBVGRZWUVFBTmUGZmO51pmzZpJ022yZd8bdKkrazoHsR3f+8ljBPeM621AqGNUqyyAwNrLE5gLOd9z7m11jmHiM45sBYQAa00mFw5o6xuWVPStB9ovfty/vE+y/eXorjyjlJyPu3LSyZEx1piEXAYgLZAmaZt0j1811Uu8h2QI5SpIAcla7QMsdWqlrLgfVnVmRClVkQjvX6+a1++Fm/e8hevkiJNNRgDAAacRcuYEKJrW6UU4sh533GhAKfJeT+DMYNSBq0PwSIm1/3BdF2Q0gmxyMdNPhrQ1aUghNR1XVYVGLP9jxhj0jXN0AujVJjcOoc1RhxHymjTNIw2hBCtYVvXZVnWbVv/8kduKFVa+3n2sw9LjCHS4yHfpaLrNXo+AIzT9qT9e/beJ6fT6Xy5WGOmEUfEJYaHn/c3N18/fctvj/LDXgzvPsbbO5XlkGVBit+hp5IxJpFS9r1wzsUY5xDWdZWGsZZcOwqDmKyW2Skw5pTyWi0anmVr7S+wlPQ/eJhBywAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd83bdc1b2db66bdb031130561ffe888/e4706/Loxone_26.avif 230w", "/en/static/cd83bdc1b2db66bdb031130561ffe888/d1af7/Loxone_26.avif 460w", "/en/static/cd83bdc1b2db66bdb031130561ffe888/c9875/Loxone_26.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cd83bdc1b2db66bdb031130561ffe888/a0b58/Loxone_26.webp 230w", "/en/static/cd83bdc1b2db66bdb031130561ffe888/bc10c/Loxone_26.webp 460w", "/en/static/cd83bdc1b2db66bdb031130561ffe888/d00b9/Loxone_26.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd83bdc1b2db66bdb031130561ffe888/81c8e/Loxone_26.png 230w", "/en/static/cd83bdc1b2db66bdb031130561ffe888/08a84/Loxone_26.png 460w", "/en/static/cd83bdc1b2db66bdb031130561ffe888/5a190/Loxone_26.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cd83bdc1b2db66bdb031130561ffe888/5a190/Loxone_26.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`select the `}<strong parentName="li">{`Virtual inputs`}</strong>{` for this.`}</li>
      <li parentName="ol">{`click on `}<strong parentName="li">{`Virtual input`}</strong>{` to create a new one.`}</li>
      <li parentName="ol">{`give the new input a name - this will be needed afterwards for the alarm server request (you should use a simple name, without spaces - or special characters).`}</li>
      <li parentName="ol">{`drag the new input onto the desktop.`}</li>
    </ol>
    <p>{`You can now connect the new input to anything you want to trigger by the alarm event on the camera. We will use the bell/Intercom we created in a `}<a parentName="p" {...{
        "href": "#display-your-camera-video-on-alarm"
      }}>{`previous step`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ec567022fcced86ef1d70dfabccc7ec7/5a190/Loxone_27.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABF0lEQVQoz52Qz0+DMBiG96d68j/TxKMmO3hSZmJmtsh2EPYLB2yUldJC+co6SkuN7mQyp/E5f0/e93t7xhhrrTGm+US1bat1q5RqlNJa27P0jjLn3PO85XKVoC1CURAE89kMY2y77ne5rkUYhiQjlOUZQQilURjRnP0pWQDs0pQxJgDKmj7E/bvV9e3iqr++wTWynVXq+JH+Lmttu2672QyHQ9+fBdGSl6SugbIcpaiEAmQpKk4IyTAWQpxIllJCVUm5bw5qt0/GZDDCgyd0/0qelWl+rN0Sot1pGKwcx5m4k8X7fJ34C+SN/Rd3PXrbTCvJvy5PLNc7PA7g4rKM4yhJdmla8AIEMMYopQIEAGjdnhuss//kA19ZAPdWrT4cAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec567022fcced86ef1d70dfabccc7ec7/e4706/Loxone_27.avif 230w", "/en/static/ec567022fcced86ef1d70dfabccc7ec7/d1af7/Loxone_27.avif 460w", "/en/static/ec567022fcced86ef1d70dfabccc7ec7/c9875/Loxone_27.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ec567022fcced86ef1d70dfabccc7ec7/a0b58/Loxone_27.webp 230w", "/en/static/ec567022fcced86ef1d70dfabccc7ec7/bc10c/Loxone_27.webp 460w", "/en/static/ec567022fcced86ef1d70dfabccc7ec7/d00b9/Loxone_27.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec567022fcced86ef1d70dfabccc7ec7/81c8e/Loxone_27.png 230w", "/en/static/ec567022fcced86ef1d70dfabccc7ec7/08a84/Loxone_27.png 460w", "/en/static/ec567022fcced86ef1d70dfabccc7ec7/5a190/Loxone_27.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ec567022fcced86ef1d70dfabccc7ec7/5a190/Loxone_27.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Connect the input to the input of the `}<em parentName="p">{`Intercom Module`}</em>{`, save the configuration and transfer it to the Loxone Miniserver.`}</p>
    <p>{`To use this input, we must now configure the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarm Server`}</a>{` on the camera. Therefore we have to know that the `}<strong parentName="p">{`Virtual Input`}</strong>{` in the Loxone Miniserver can be reached via the `}<a parentName="p" {...{
        "href": "https://www.loxwiki.eu/display/LOX/REST+Webservice"
      }}>{`REST API`}</a>{` under the path `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/dev/sps/io/<Name of the Virtual Input>/`}</code>{`. This path must be preceded by the IP address of the Loxone Miniserver - e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.58`}</code>{` - and the state we want to switch the input to must be appended at the end:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.58/dev/sps/io/alarmserver/ON`}</code>{` (permanently on)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.58/dev/sps/io/alarmserver/OFF`}</code>{` (permanently off)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.58/dev/sps/io/alarmserver/Pulse`}</code>{` (briefly on and then off again)`}</li>
    </ul>
    <p>{`We want the camera to send a pulse to the alarm server only for a short time and it will immediately jump back into the `}<strong parentName="p">{`OFF state`}</strong>{` and `}<em parentName="p">{`armed`}</em>{` again for the next alarm. We therefore select the last of the three commands above and enter it into the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Web interface`}</a>{` of our camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/99535f3d01f2d1911d23bf2672049505/5a190/Loxone_28.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADE0lEQVQ4y3WRy28bRRzHF/UOEpwoEoIbNw514YRqRy7YruM2bQMqgQIC/oF4vetd23nYqcmZIgSIh8QlkFQiFHAiFUpUVEgQPRBBBQkoSh1BvPbsw97Ma+dR7W4acuGrn1ZzmM/OfL6jZPOFbL6QPz2SPZXPZHOZXG64UEin0yeSyWQqdXhOpFJDJzPP5fKZ4ZEXxi4WzpxVisWiWiyOq9przS/G3lq6cOnr89Nfna9/M9poHczzjda5euvl2eWNrbbr2F6/HwTB5uamouu6rmm6Ufno+p0Pf3A+WOm+e717+TvrnWguf7s/b1+z3vve6rgEQbi8tLS4uDg3N6eUdGNc1Wq16sad7qc3u5/c2P3sJ2t+1VpYtRbWrPm1cD2/as392Pl45Z/3V6xfbm8/+sjD9x05kjieUFRNL6qaaVY7XdDz4C7oW/bA2yM+ZgPEBijwUeBj1kesD4MtENz+aydxPPH40aO5TFZRS3qxpJXNim0DSjHc8ynFMgqlBMG9IKBCCsG5YAEXotsDL1589YkHH0qnkkpJKxdLmlGp2MCmlDLG5L2QKDRKEEVK6Xru2CsvPfbA/SfTQ4ppmuOqVjarAAAUJd4kpUQI+b4PIfR9HyFECBFC9Hq90QujTx17cnj41D6sGxVgA4wxhBDj/WtjjAkhnPP4WAihlHK303lmKJl4+tjIuZEQjp0BAPEhEEIhROQcRgjBGOOcE0KklACAZ3O5ZCr5xpuvh7AaOTuOwxnDGCOEOOdSShHloIL4L67rNJvN6enper2uGIYRFmZWbTssLBaT/xNrwLf/BZdmGhMTE1NTU4oatR1f+3BbsTPGmHPO9hPQgPdsZ2amMTk5GcMH72wTQiCEsWcMQ4RowAgNKKEUY8GY6ziNxj24pJdDZzN0FkLEDcdwEFDGgsPOUkrP8/6DTcMol8u1Ws3zvIMd0SL8/rYDr6z1rv6+t33jlvv5gnNlEfzx58zs7GTsbERwtVptt9uDwcBxHNd1Xc9zPc/ve+t/d1o/b19b3924eav95dWd1vLWr+uNZjOG7wLSS/ZFLiTD+QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99535f3d01f2d1911d23bf2672049505/e4706/Loxone_28.avif 230w", "/en/static/99535f3d01f2d1911d23bf2672049505/d1af7/Loxone_28.avif 460w", "/en/static/99535f3d01f2d1911d23bf2672049505/c9875/Loxone_28.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/99535f3d01f2d1911d23bf2672049505/a0b58/Loxone_28.webp 230w", "/en/static/99535f3d01f2d1911d23bf2672049505/bc10c/Loxone_28.webp 460w", "/en/static/99535f3d01f2d1911d23bf2672049505/d00b9/Loxone_28.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99535f3d01f2d1911d23bf2672049505/81c8e/Loxone_28.png 230w", "/en/static/99535f3d01f2d1911d23bf2672049505/08a84/Loxone_28.png 460w", "/en/static/99535f3d01f2d1911d23bf2672049505/5a190/Loxone_28.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/99535f3d01f2d1911d23bf2672049505/5a190/Loxone_28.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please note that you have to enter the login below to connect to the miniserver via Loxone Config!`}</p>
    <p>{`Accept these settings and trigger an alarm on the camera - e.g. with the `}<a parentName="p" {...{
        "href": "#example-1-triggering-an-alarm"
      }}>{`virtual button we created before`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/073ec0615072ad7913830cefb0da0da8/5a190/Loxone_29.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100.8695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEnElEQVQ4y13LaUyTdxwH8H+fnmAUESgELR6lKmel0NLS9un1lKstWAptLdDSIiJ9Sg/aPm1paZHOAToYcsw66xauEfGcnM5kW5ZsyVy2+WpColNfbIhxur1bXIyLbkvMfvnk++b7/QG+SCoQS2EJUsTmFbI4zGJOCZefW8jan1t4II/5r3zmwf/kM1kcvlhepYQlCHC6XA6n04th1y5f/GxlcXX5xs2VxVurS2+7ubK4unRjdXlhdXnh+pVL4XAkHI6EQiHgwzDM6+kOBr9de7z+5OXa5ou7j1/c/+3VvX88fXX/+at7T1+uP/lrffPF3Y0/b69t+AOBzs5ODMOAB+v2eIMef7g+9qh55o+m6WfN088VY48UYw8UZx4ozzzUxjerY0+Q8Q3J8CPVxC/eqZ9ou7OTtm3l8XjgRNjVHzYbmlvk3jlF6NPKwKVK/2WOdUromBJ2TsH2aTl2kd81z7LOHDo2ybfPmkLn0jN3pqYkSaUSEHA26CpStm1LSk5OpiQkAAAADsLj8eCtgyAID+Few0MQBFEoZBKJwOeXAlVNza4s+v/WOBwueXtSBpWalpqakpqKw+HergAAWVk0BJEArUa9MzOTkZND30OnZdCydu0pzCtSaHQaQ/Ph5uZavb6cLxCwWHl0eskBRiWPl0alJiVt37IlEYbLgEmrlfJFxg5UW6dTwtUalSEaef/nX3//+s762NXr8YXFQYdnxOPHWizvWEwmTX0mjUbfu3vfXlp1VTkw67QCNldtNFbJKkW57Dqlzm3v+fHh5trGs9H5K33j53rd3VFfENUdOWW3wWJpdva+ktISXhnPYNADi17HYx1CNLUwh8dnFKjkNQaFLjq7NDp3PRKfaUFdWCvqQ51mpcqqPaJpNDj8Pj0sNkskXp8HmHUNXGa+vE4pE8NICb8clmkEyPDcorOvHw1HTVbUbUMdx9u0coRdKuwdGPnk2lJjTkFtbk6HtR1Y9A3c/IP8ColAIBAXc4Vs3uFS4fDc5OD02ej5U50h1/h8fHz2g6oyrhCpWv3q9uzVy2o6Q1XMev3cqtNycxiyWjmCiCTMIphdqpXIl7//buHODyNXLgxMnonfmI7Nx2ukYiFSsfTl5+c/PotkZNbLhE6XDVj0Ws7BbEQlkyFwWW4e/1BRvQiZ/+KbCyu3gqffdfnt6DGT9aipXikXIrKJyQuTs+ftFqO5qcHpRIFJV8/cmVnE2Mdm0Pn7s0UFBWox3HG8zWo75vCgXT60p8/bOxAcGIlWKCuNqC0+9eHI+FC0vzcQxIDZ1ChkFxv1dRajrtV8xGZv64n6J+Y+GpqJvTcT6z93um/05MDE4HBsqMnShKjrIid7Mb872n8i1BMADodVVC6tMzS0tDUe7TCh7vaugKPdY+8fGcJ6uk3traOxieNuNDIY0TQ2cCSy/GJ2TmGerQvFfG6gVtcAAIhEIplMJlPeBIUCcPhgT8RsaROIpKNjE1uTkikUMpFEgvAECMLjIAgAoFRUgcO1KgIEKGQimYQnE98gQIkJZGo6NY2atiNlR3pGemIihUiAyCQChUwgk4gJFBIOAGV1xd9vxaVtSgwMiAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/073ec0615072ad7913830cefb0da0da8/e4706/Loxone_29.avif 230w", "/en/static/073ec0615072ad7913830cefb0da0da8/d1af7/Loxone_29.avif 460w", "/en/static/073ec0615072ad7913830cefb0da0da8/c9875/Loxone_29.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/073ec0615072ad7913830cefb0da0da8/a0b58/Loxone_29.webp 230w", "/en/static/073ec0615072ad7913830cefb0da0da8/bc10c/Loxone_29.webp 460w", "/en/static/073ec0615072ad7913830cefb0da0da8/d00b9/Loxone_29.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/073ec0615072ad7913830cefb0da0da8/81c8e/Loxone_29.png 230w", "/en/static/073ec0615072ad7913830cefb0da0da8/08a84/Loxone_29.png 460w", "/en/static/073ec0615072ad7913830cefb0da0da8/5a190/Loxone_29.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/073ec0615072ad7913830cefb0da0da8/5a190/Loxone_29.png",
              "alt": "Loxone Homeautomation",
              "title": "Loxone Homeautomation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The alarm is triggered on the camera and the alarm server is contacted. The Loxone Miniserver receives the message and activates the intercom - on all screens where the `}<a parentName="p" {...{
        "href": "#smart-home-app-and-webui"
      }}>{`Loxone WebUI or App`}</a>{` is opened, the live video of the triggering camera is shown to you immediately.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      